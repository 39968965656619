import './TShirtCanvas.css';

import { IState, TShirtSide } from '../../interface';
import { Image, Spin, Typography } from 'antd';
import { useCallback, useLayoutEffect, useRef } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { fabric } from 'fabric';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../hooks/useWindowSize';

const { Text } = Typography;

interface ITShirtCanvasProps {
	canvas: fabric.Canvas;
}

const TShirtCanvas = (props: ITShirtCanvasProps) => {
	const size = useWindowSize();
	const imageDivRef = useRef<any>();
	const state = useSelector((state: IState) => state);
	const { canvas: canvasState, loading: loadingState } = state;

	const {
		tShirtSide,
		frontTShirtImage,
		backTShirtImage,
		tShirtSize: selectedTShirtSize,
		tShirtColor,
	} = canvasState;

	const { canvasLoading } = loadingState;

	const { canvas } = props;

	const updateCanvasDimensions = useCallback(() => {
		const { width } = getImageDimensions();
		const ratio = canvas?.getWidth() / canvas?.getHeight();
		const scale = width / canvas?.getWidth();
		const zoom = canvas?.getZoom() * scale;

		canvas?.setDimensions(
			{
				width,
				height: width / ratio,
			},
			{
				cssOnly: false,
			}
		);

		canvas?.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
	}, [canvas]);

	useLayoutEffect(() => {
		updateCanvasDimensions();
	}, [canvas, size, updateCanvasDimensions]);

	const getImageDimensions = () => {
		const { width, height } = imageDivRef.current.getBoundingClientRect();

		return {
			width: width / 2.4,
			height: height / 2,
		};
	};

	return (
		<>
			<div ref={imageDivRef}>
				<Image
					style={{ backgroundColor: tShirtColor }}
					preview={false}
					src={
						tShirtSide === TShirtSide.FRONT ? frontTShirtImage : backTShirtImage
					}
					alt="tshirt"
					width="100%"
					onLoad={updateCanvasDimensions}
				/>
				<div className="droparea" id="tour-step2">
					<canvas id="canvas"></canvas>
				</div>
			</div>
			{tShirtSide === TShirtSide.FRONT && (
				<Text className="img-label" data-html2canvas-ignore>
					{selectedTShirtSize[1][0]}
				</Text>
			)}
			{canvasLoading && (
				<Spin
					indicator={
						<LoadingOutlined
							style={{
								fontSize: 50,
								color: '#fdc7d7',
								position: 'absolute',
								top: '10%',
								left: '46%',
							}}
							spin
						/>
					}
				/>
			)}
		</>
	);
};

export default TShirtCanvas;
