import {
	Alert,
	Button,
	Empty,
	Image,
	Input,
	Modal,
	Pagination,
	Skeleton,
	message,
} from 'antd';
import { ISvgData, ISvgImage } from '../../interface';
import React, { useState } from 'react';

import CanvasActions from '../../redux/CanvasRedux';
import { SearchOutlined } from '@ant-design/icons';
import { fabric } from 'fabric';
import { reportError } from '../../services/Reporting';
import { useDispatch } from 'react-redux';

const APP_URL = process.env.REACT_APP_URL || '';

interface ICanvasImageControls {
	canvas: fabric.Canvas;
}

const ImageControls = (props: ICanvasImageControls) => {
	const { canvas } = props;
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [svgData, setSvgData] = useState<ISvgData>();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(12);
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { items = [], pages = 1, page = 1, count = pageSize } = svgData || {};
	const totalItems = pages ? pages * count : page * count;

	const dispatch = useDispatch();

	const onSearchInputUpdate = (
		value: string,
		event?:
			| React.ChangeEvent<HTMLInputElement>
			| React.MouseEvent<HTMLElement, MouseEvent>
			| React.KeyboardEvent<HTMLInputElement>
			| undefined
	) => {
		// setSearchQuery(value);
		// if (value) {
		searchSvgClipArt();
		setCurrentPage(1);
		// }
	};

	const onPaginationChange = (
		page: number,
		updatedPageSize?: number | undefined
	) => {
		setCurrentPage(page);
		setPageSize(updatedPageSize || pageSize);

		searchSvgClipArt();
	};

	const searchSvgClipArt = async () => {
		setLoading(true);

		try {
			const url = new URL(`${APP_URL}/api/clipart`);
			const params = {
				page: currentPage,
				num: pageSize,
				query: searchQuery,
				orderby: 'date',
			};
			Object.keys(params).forEach((key) =>
				// @ts-ignore
				url.searchParams.append(key, params[key])
			);

			// @ts-ignore
			const response = await fetch(url);
			const body = await response.json();

			setSvgData(body);
		} catch (error) {
			reportError(error);
		}

		setLoading(false);
	};

	const addSvgToCanvas = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		dispatch(CanvasActions.saveCanvasState({ loading: false }));

		try {
			setIsModalVisible(false);

			// @ts-ignore
			const svgSrc = event.target.src;

			fabric.loadSVGFromURL(svgSrc, (objects: fabric.Object[], options) => {
				var loadedObject = fabric.util.groupSVGElements(objects, options);

				loadedObject
					.set({
						left: 50,
						top: 50,
					})
					.scale(0.5);

				// @ts-ignore
				canvas.add(loadedObject).setActiveObject(loadedObject).renderAll();
			});
		} catch (error) {
			reportError(error);
			message.error('Failed to add image to canvas, please try again');
		}

		dispatch(CanvasActions.saveCanvasState({ loading: false }));
	};

	return (
		<>
			<Button
				type="dashed"
				size="large"
				onClick={() => setIsModalVisible(true)}
				icon={<SearchOutlined />}
				title="Image Search"
				style={{ width: 104, height: 104, marginBottom: 6 }}
			>
				<br />
				Search
			</Button>
			<Modal
				title="Image Search"
				centered
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				okButtonProps={{ hidden: true }}
				cancelText={'Close'}
				width={1000}
			>
				<Input.Search
					placeholder="Input search text"
					size="large"
					onChange={(event) => setSearchQuery(event.target.value)}
					onSearch={onSearchInputUpdate}
					style={{ marginBottom: '20px' }}
					allowClear
					enterButton="Search"
					autoFocus
				/>
				{loading ? (
					<Skeleton active />
				) : (
					<>
						{items.length ? (
							<Alert
								message="Click on the image to add it to the canvas"
								type="info"
								showIcon
								style={{ marginBottom: 20 }}
							/>
						) : null}
						{items
							.filter((item: ISvgImage) => parseInt(item.filesize) / 1024 < 500) // svg file size < 500kb
							.map((svgData: ISvgImage, index: number) => {
								const { svgurl, title } = svgData;
								return (
									<Image
										key={`${title}${index}`}
										src={svgurl}
										alt={title}
										width={150}
										height={150}
										preview={false}
										onClick={addSvgToCanvas}
										placeholder={<Skeleton.Image />}
										style={{ cursor: 'pointer', margin: '0 20px', padding: 10 }}
									/>
								);
							})}
						{items.length ? (
							<Pagination
								current={currentPage}
								total={totalItems}
								pageSize={pageSize}
								pageSizeOptions={['5', '10', '15', '20', '25']}
								showTotal={(total) => `Total ${total} items`}
								onChange={onPaginationChange}
								showSizeChanger
							/>
						) : (
							<Empty />
						)}
					</>
				)}
			</Modal>
		</>
	);
};

export default ImageControls;
