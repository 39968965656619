import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { IState } from '../../interface';
import React from 'react';
import { useSelector } from 'react-redux';

interface IAuthenticatedRoute {
	component:
		| React.ComponentType<RouteComponentProps<any>>
		| React.ComponentType<any>;
	path: string;
}

export const AuthenticatedRoute = (props: IAuthenticatedRoute) => {
	const { component: Component, path } = props;
	const user = useSelector((state: IState) => state.auth.user);

	return (
		<Route
			path={path}
			render={(props) =>
				user ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default AuthenticatedRoute;
