import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

export const Pricing = () => {
	return (
		<section>
			<div className="py-16 radius-for-skewed">
				<div className="container mx-auto px-4">
					<div className="max-w-2xl mx-auto text-center mb-16">
						<h2 className="mb-2 text-4xl lg:text-5xl font-bold font-heading text-purple-800">
							Pricing
						</h2>
						<p className="mb-6 text-pink-600">
							Various products are availble at different price point.
						</p>
					</div>
					<div className="flex flex-wrap -mx-4 mb-10">
						<div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
							<ScrollAnimation animateOnce={true} animateIn="slideInLeft">
								<div className="p-8 bg-white shadow rounded">
									<h3 className="mb-2 text-2xl font-bold font-heading">
										T-Shirt
									</h3>
									<span className="text-6xl font-bold">$18</span>
									<p className="mt-3 mb-6 text-gray-500 leading-loose"></p>
									<ul className="mb-6 text-gray-500">
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-green-400"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>One side print</span>
										</li>
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-green-400"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>Including t-shirt</span>
										</li>
									</ul>
								</div>
							</ScrollAnimation>
						</div>
						<div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
							<ScrollAnimation animateOnce={true} animateIn="pulse">
								<div className="p-8 bg-purple-900 shadow rounded">
									<h3 className="mb-2 text-2xl font-bold text-white">
										T-Shirt
									</h3>
									<span className="text-6xl font-bold text-white">$25</span>
									<p className="mt-3 mb-6 leading-loose text-gray-50"></p>
									<ul className="mb-6 text-gray-50">
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-pink-600"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>Front side print</span>
										</li>
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-pink-600"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>Back side print</span>
										</li>
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-pink-600"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>Including t-shirt</span>
										</li>
									</ul>
								</div>
							</ScrollAnimation>
						</div>
						<div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
							<ScrollAnimation animateOnce={true} animateIn="slideInRight">
								<div className="p-8 bg-white shadow rounded">
									<h3 className="mb-2 text-2xl font-bold font-heading">
										Hoodie
									</h3>
									<span className="text-6xl font-bold">$40</span>
									<p className="mt-3 mb-6 text-gray-500 leading-loose"></p>
									<ul className="mb-6 text-gray-500">
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-green-400"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>One side print</span>
										</li>
										<li className="mb-2 flex">
											<svg
												className="mr-2 w-5 h-5 text-green-400"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													clipRule="evenodd"
												/>
											</svg>
											<span>Including hoodie</span>
										</li>
									</ul>
								</div>
							</ScrollAnimation>
						</div>
					</div>
					<div className="max-w-xl mx-auto mb-10 text-center">
						<Link
							className="inline-block w-full lg:w-auto py-2 px-6 leading-loose text-white font-semibold bg-pink-600 border-2 border-pink-400 hover:border-pink-500 hover:text-white rounded-l-xl rounded-t-xl transition duration-200"
							to="/design"
						>
							Get started
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Pricing;
