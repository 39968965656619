import { Button, Col, Input, Modal, Row } from 'antd';
import { FileImageTwoTone, SaveFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import { IState } from '../../interface';
import { downloadCanvasImage } from '../../utils/fabricCanvasHelper';

interface ICanvasSaveControls {
	canvas: fabric.Canvas;
}

const SaveControls = (props: ICanvasSaveControls) => {
	const { canvas } = props;
	const [shirtTitle, setShirtTitle] = useState<string>();
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { tShirtColor } = useSelector((state: IState) => state.canvas);

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	const addToDesignList = () => {
		dispatch(CanvasActions.addToFeatured({ shirtTitle }));
		closeModal();
	};

	const saveImageHandler = (
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		downloadCanvasImage(canvas, tShirtColor);
	};

	return (
		<Row gutter={[10, 10]}>
			<Col flex={1}>
				<Button
					shape="round"
					size={'large'}
					icon={<FileImageTwoTone style={{ fontSize: '26px' }} />}
					onClick={saveImageHandler}
					block
				>
					Download image
				</Button>
			</Col>
			<Col flex={1}>
				<Button
					shape="round"
					size={'large'}
					icon={<SaveFilled style={{ fontSize: '26px', color: '#08c' }} />}
					onClick={openModal}
					block
				>
					Add to designs
				</Button>
			</Col>
			<Modal
				title="TShirt Design Title"
				width={'50%'}
				visible={isModalVisible}
				onOk={addToDesignList}
				onCancel={closeModal}
			>
				<Input
					value={shirtTitle}
					onChange={(event) => setShirtTitle(event.target.value)}
					placeholder={'TShirt title'}
				/>
			</Modal>
		</Row>
	);
};

export default SaveControls;
