import { Helmet } from 'react-helmet';
import { IState } from '../interface';
import { Layout } from 'antd';
import { SigninForm } from '../components/Login/SigninForm';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const { Content } = Layout;

export const Login = () => {
	const user = useSelector((state: IState) => state.auth.user);
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.push('/');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Login</title>
			</Helmet>
			<SigninForm />
		</Content>
	);
};

export default Login;
