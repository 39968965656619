import {
	addToFeatured,
	captureDesignAndUploadToStorage,
	saveCanvasState,
} from './canvasSaga';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchCart, resetCart, updateCart } from './cartSaga';

import { AuthTypes } from '../redux/AuthRedux';
import { CanvasTypes } from '../redux/CanvasRedux';
import { CartTypes } from '../redux/CartRedux';

function* appSagas() {
	yield all([
		takeLatest(CartTypes.FETCH_CART_REQUEST, fetchCart),
		takeLatest(CartTypes.RESET_CART, resetCart),
		takeLatest(
			[
				CartTypes.ADD_CART_ITEM,
				CartTypes.REMOVE_CART_ITEM,
				CartTypes.UPDATE_CART_ITEM,
			],
			updateCart
		),
		takeLatest(CanvasTypes.ADD_TO_FEATURED, addToFeatured),
		takeEvery(
			[CanvasTypes.SAVE_CANVAS_STATE, AuthTypes.LOGIN_SUCCESS],
			saveCanvasState
		),

		takeLatest(
			[CanvasTypes.UPLOAD_DESIGN_TO_STORAGE],
			captureDesignAndUploadToStorage
		),
	]);
}

export default appSagas;
