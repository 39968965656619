interface IFeatureList {
	items: string[];
}

export const FeatureList = (props: IFeatureList) => {
	const { items } = props;

	return (
		<ul className="text-gray-500 font-bold">
			{items.map((feature) => (
				<li className="flex mb-4" key={feature}>
					<svg
						className="mr-2 w-6 h-6 text-green-400"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clipRule="evenodd"
						/>
					</svg>
					<span>{feature}</span>
				</li>
			))}
		</ul>
	);
};

export default FeatureList;
