import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import { Cascader } from 'antd';
import { CascaderValueType } from 'antd/lib/cascader';
import { IState } from '../../interface';
import { tshirtSizeOptions } from '../../data';

export const TShirtSizeSelector = () => {
	const state = useSelector((state: IState) => state.canvas);
	const { tShirtSize } = state;
	const dispatch = useDispatch();

	const onSizeChange = (value: CascaderValueType) => {
		dispatch(CanvasActions.saveCanvasState({ tShirtSize: value }));
	};

	return (
		<Cascader
			size="large"
			//@ts-ignore
			options={tshirtSizeOptions}
			onChange={onSizeChange}
			value={tShirtSize}
			allowClear={false}
			style={{ marginBottom: 10 }}
		/>
	);
};

export default TShirtSizeSelector;
