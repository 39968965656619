import { Button, Image, Space, Spin, Table } from 'antd';

import { Content } from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';
import { IAddress } from '../interface';
import { LoadingOutlined } from '@ant-design/icons';
import { useOrder } from '../hooks/useOrder';

const columns = [
	{
		title: 'Order #',
		dataIndex: 'orderId',
		key: 'orderId',
	},
	{
		title: 'Shipping Address',
		dataIndex: 'shippingAddress',
		key: 'shippingAddress',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
];

const expandedRowRender = ({ items }: { items: any }) => {
	const columns = [
		{
			dataIndex: 'image',
			key: 'image',
			render: (imageUrl: string) => (
				<Space direction="vertical">
					<Image
						src={imageUrl}
						width={80}
						height={100}
						preview={false}
						fallback="Image not found"
					/>
					<Button type="link">
						<a href={imageUrl} target="_blank" rel="noreferrer">
							View
						</a>
					</Button>
				</Space>
			),
		},
		{
			title: 'T-Shirt Size',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
		},
	];

	var itemList = JSON.parse(items);
	const data = Object.keys(itemList)
		.map((key) => itemList[key])
		.map((item) => {
			const { id, quantity, price, size, images } = item;
			return {
				key: id,
				quantity,
				price: `$${price}`,
				image: images[0],
				size,
			};
		});
	return <Table columns={columns} dataSource={data} pagination={false} />;
};

export const Orders = () => {
	const { orders, loading } = useOrder();

	const getShippingAddress = (address: IAddress) => {
		const { city, country, line1, line2, postal, state } = address;
		return `${line1}, ${line2 ?? ''} ${city}, ${state}, ${
			postal ?? ''
		} ${country}`;
	};

	const orderData = orders.map((order) => {
		const { data }: any = order;
		const { orderId, items, paymentStatus, shipping }: any = data;
		const { address } = shipping || { address: '' };

		const shippingAddress = address ? getShippingAddress(address) : '';

		return {
			key: orderId,
			orderId,
			items,
			status: paymentStatus,
			shippingAddress,
		};
	});

	return (
		<Content style={{ padding: '5% 15%', marginTop: 75 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Orders</title>
			</Helmet>
			<Table
				columns={columns}
				dataSource={orderData}
				pagination={false}
				loading={
					loading && {
						indicator: (
							<Spin
								size="large"
								indicator={<LoadingOutlined spin />}
								style={{
									display: 'flex',
									justifyContent: 'center',
									margin: '5% auto',
								}}
							/>
						),
					}
				}
				expandable={{ expandedRowRender }}
			/>
		</Content>
	);
};

export default Orders;
