import BannerTShirtCanvas from './Canvas/BannerTShirtCanvas';
import { useHistory } from 'react-router-dom';

interface ITShirtDesign {
	frontCanvasData: string;
	shirtTitle: string;
	tShirtColor: string;
}

export const TShirtDesign = (props: ITShirtDesign) => {
	const { frontCanvasData, shirtTitle, tShirtColor } = props;
	const history = useHistory();

	const navigateToDesigner = (id: string) => {
		history.push({ pathname: `/design/${id}` });
	};

	return (
		<>
			<BannerTShirtCanvas
				data={frontCanvasData}
				id={shirtTitle}
				backgroundImage={require('../assets/images/tshirt_front.webp').default}
				selectedColor={tShirtColor}
				selectedSize={11}
			/>
			<p
				className="text-lg font-bold cursor-pointer text-cyan-600"
				onClick={() => navigateToDesigner(shirtTitle)}
				title={'Customize'}
			>
				{shirtTitle} &rarr;{' '}
			</p>
		</>
	);
};

export default TShirtDesign;
