import fetch from 'node-fetch';

const APP_URL = process.env.REACT_APP_URL;

export const reportError = async (errorInfo: any) => {
	try {
		console.log(errorInfo);

		const { message, stack } = errorInfo;

		const data = {
			message,
			stack,
		};

		await fetch(`${APP_URL}/api/monitor`, {
			method: 'POST',
			body: JSON.stringify({ data }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	} catch (error) {
		console.error(error);
	}
};
