import { FirebaseDocRef, ICanvasState } from '../interface';
import { call, put, select } from 'redux-saga/effects';
import { firebaseAuth, firestore } from '../config/firebase';

import { CanvasSelectors } from '../redux/CanvasRedux';
import CartActions from '../redux/CartRedux';
import { Modal } from 'antd';
import { getCanvasDataUrl } from './../utils/fabricCanvasHelper';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { reportError } from '../services/Reporting';
import { uploadScreenshot } from '../services/uploadScreenshot';

export function* saveCanvasState(_: any) {
	try {
		const user = firebaseAuth().currentUser;
		if (!user) {
			return;
		}

		const canvasState: ICanvasState = yield select(CanvasSelectors.canvasState);
		const {
			id: canvasId,
			printBackSide,
			tShirtColor,
			frontCanvasData = '',
			backCanvasData = '',
		} = canvasState;

		const canvasRef = firestore()
			.doc(`canvas/${user.uid}`)
			.collection('data')
			.doc(canvasId);

		const doc: FirebaseDocRef = yield call([canvasRef, canvasRef.get]);
		const canvasData = doc.data();

		yield call(
			// @ts-ignore
			[canvasRef, canvasRef.set],
			{
				...canvasData,
				frontCanvasData,
				backCanvasData,
				printBackSide,
				tShirtColor,
			},
			{ mege: true }
		);
	} catch (error) {
		reportError(error);
		if (error.message.includes('is longer than')) {
			Modal.error({
				title: 'Failed to save',
				content: 'Size of your canvas data is greater than 1Mb.',
				onOk: () => {},
			});
		}
	}
}

export function* addToFeatured(action: any) {
	try {
		const user = firebaseAuth().currentUser;
		if (!user) {
			message.error('Please login to continue');
			return;
		}

		const { data } = action;
		const { shirtTitle } = data;
		const canvasId: string = yield select(CanvasSelectors.id);
		const canvasRef = firestore()
			.doc(`canvas/${user.uid}`)
			.collection('data')
			.doc(canvasId);

		const doc: FirebaseDocRef = yield call([canvasRef, canvasRef.get]);
		const canvasData = doc.data();

		if (!canvasData) {
			message.error('Data not found, aborting');
			return;
		}

		const designRef = firestore().doc(`designs/${shirtTitle}`);

		yield call(
			// @ts-ignore
			[designRef, designRef.set],
			{
				...canvasData,
				shirtTitle,
			},
			{ mege: true }
		);
	} catch (error) {
		reportError(error);
		message.error('Failed to save!');
	}
}

export function* captureDesignAndUploadToStorage(action: any) {
	yield put(CartActions.setCartLoading(true));

	let downloadUrl = '';
	const { canvas, canvasId } = action.data;
	const tShirtColor: string = yield select(CanvasSelectors.tShirtColor);

	try {
		const base64EncodedImageString: string = yield call(
			getCanvasDataUrl,
			canvas,
			tShirtColor
		);

		if (base64EncodedImageString) {
			downloadUrl = yield call(
				uploadScreenshot,
				base64EncodedImageString,
				canvasId
			);
		}
	} catch (error) {
		reportError(error);
	}

	yield put(
		CartActions.updateCartItem({
			id: canvasId,
			images: [downloadUrl],
		})
	);

	yield put(CartActions.setCartLoading(false));

	yield put(push('/checkout'));
}
