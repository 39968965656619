import { Button, Result, Skeleton } from 'antd';

import ErrorOccurred from './Icons/ErrorOccurred';
import Icon from '@ant-design/icons';
import ResultNotFound from './Icons/ResultNotFound';
import TShirtDesign from './TShirtDesign';
import { useDesigns } from '../hooks/useDesigns';
import { useHistory } from 'react-router';

interface IOurDesigns {
	limit: number;
}

const ErrorOccurredIcon = () => <Icon component={ErrorOccurred} />;
const ResultNotFoundIcon = () => <Icon component={ResultNotFound} />;

export const TShirtDesignList = (props: IOurDesigns) => {
	const { limit } = props;
	const history = useHistory();
	const { designs, loading, error } = useDesigns(limit);

	if (loading) {
		return <Skeleton active avatar paragraph={{ rows: 10 }} />;
	}

	if (error) {
		return (
			<Result
				icon={<ErrorOccurredIcon />}
				title="Oops! something went wrong"
				subTitle="Please refresh to try again"
				extra={
					<Button type="primary" onClick={() => history.go(0)} key="refresh">
						Refresh
					</Button>
				}
			/>
		);
	}

	if (!designs.length) {
		return (
			<Result
				icon={<ResultNotFoundIcon />}
				title="No results found"
				subTitle="Please refresh to try again"
				extra={
					<Button type="primary" onClick={() => history.go(0)} key="refresh">
						Refresh
					</Button>
				}
			/>
		);
	}

	return (
		<section className="text-gray-600 body-font">
			<div className="container mx-auto">
				<div className="flex flex-wrap">
					{designs.map((design) => {
						const { data } = design;
						const { frontCanvasData, shirtTitle, tShirtColor } = data;

						return (
							<div
								className="mb-6 w-full md:w-1/2 lg:w-1/3 px-3"
								key={shirtTitle}
							>
								<div className="py-4 bg-white rounded-2xl shadow text-center">
									<TShirtDesign
										frontCanvasData={frontCanvasData}
										shirtTitle={shirtTitle}
										tShirtColor={tShirtColor}
										key={shirtTitle}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default TShirtDesignList;
