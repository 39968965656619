import {
	AlignCenterOutlined,
	AlignLeftOutlined,
	AlignRightOutlined,
	BoldOutlined,
	ItalicOutlined,
	StrikethroughOutlined,
	UnderlineOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Divider,
	Input,
	InputNumber,
	Row,
	Select,
	Typography,
} from 'antd';

import { ColorResult } from 'react-color';
import { IState } from '../../interface';
import TextColorPicker from '../TextColorPicker/TextColorPicker';
import { fabric } from 'fabric';
import { fonts } from '../../data';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const { TextArea } = Input;
const { Title } = Typography;

interface ICanvasText {
	canvas: fabric.Canvas;
}

const buttonStyles = { width: '100%', height: '50px' };

const iconStyles = {
	fontSize: '26px',
};

const alignmentIconStyles = {
	...iconStyles,
	padding: 5,
};

const TextControls = (props: ICanvasText) => {
	const { canvas } = props;
	const canvasState = useSelector((state: IState) => state.canvas);
	const { showTextControls } = canvasState;
	const { Option } = Select;
	const [canvasText, setCanvasText] = useState<string>('');
	const [textColor, setTextColor] = useState<string>('#FFFFFF');
	const [fontSize, setfontSize] = useState<number>(38);
	const [selectedFont, setSelectedFont] = useState<string>('Alaska Script');
	const [fontSelectorOpen, setFontSelectorOpen] = useState<boolean>(false);

	const addText = () => {
		if (canvasText) {
			const textToAdd = new fabric.IText(canvasText, {
				fill: textColor,
				left: 10,
				top: 50,
				padding: 5,
				fontFamily: selectedFont,
			});

			const textToAddObject = canvas.add(textToAdd) as fabric.Canvas;
			textToAddObject.setActiveObject(textToAdd);

			setCanvasText('');
		}
	};

	const updateText = (event: any) => {
		setCanvasText(event.target.value);
	};

	const updateTextColor = (color: ColorResult) => {
		const activeColor = color.hex;
		setTextColor(activeColor);
		const activeObjects: fabric.Object[] = canvas.getActiveObjects();
		activeObjects.forEach((activeObject: fabric.Object) => {
			const activeObjectType = activeObject?.get('type');
			if (activeObjectType === 'text' || activeObjectType === 'i-text') {
				activeObject.set('fill', activeColor);
			}
		});
		canvas.fire('object:modified', {});
		canvas.renderAll();
	};

	const setActiveStyle = (
		styleName: any,
		value?: string | number | boolean
	) => {
		const activeObjects: fabric.Object[] = canvas.getActiveObjects();
		activeObjects.forEach((object: fabric.Object) => {
			const { type } = object;
			if (type === 'text' || type === 'i-text') {
				const activeObject = object as any;
				const currentActiveStyle = activeObject[styleName];
				switch (styleName) {
					case 'underline':
					case 'linethrough':
						activeObject.set(styleName, !currentActiveStyle);
						break;

					case 'fontWeight':
						activeObject.set(
							styleName,
							currentActiveStyle === 'bold' ? '' : 'bold'
						);
						break;

					case 'fontStyle':
						activeObject.set(
							styleName,
							currentActiveStyle === 'italic' ? '' : 'italic'
						);
						break;

					case 'fontFamily':
						activeObject.set(styleName, value);
						break;

					default:
						activeObject.set(styleName, value);
				}
			}
			canvas.fire('object:modified', { target: object });
			canvas.renderAll();
		});
	};

	const boldText = () => {
		setActiveStyle('fontWeight');
	};

	const italicText = () => {
		setActiveStyle('fontStyle');
	};

	const underlineText = () => {
		setActiveStyle('underline');
	};

	const strikethroughText = () => {
		setActiveStyle('linethrough');
	};

	const updateTextFontSize = (value: number | string | undefined) => {
		const fontSize = Number(value) || 16;
		setActiveStyle('fontSize', fontSize);
		setfontSize(fontSize);
	};

	const updateFont = (value: any) => {
		setSelectedFont(value);
		setActiveStyle('fontFamily', value);
	};

	const updateTextAlign = (value: string) => {
		setActiveStyle('textAlign', value);
	};

	return (
		<>
			<Title level={4}>Add Text</Title>
			<Row gutter={[10, 10]}>
				<Col flex={1}>
					<TextArea
						rows={4}
						placeholder="Add your text here"
						onChange={updateText}
						value={canvasText}
					/>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col flex={1} style={{ margin: '10px 0px' }}>
					<Button
						type="primary"
						shape="round"
						size={'large'}
						onClick={addText}
						disabled={!canvasText}
						block
					>
						Add text{' '}
					</Button>
				</Col>
			</Row>
			<Divider />
			<Row gutter={[5, 10]}>
				<Col flex={1}>
					<TextColorPicker
						textColor={textColor}
						showTextColorPicker={!showTextControls}
						updateTextColorHandler={updateTextColor}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="bold"
						size="large"
						icon={<BoldOutlined style={{ ...iconStyles }} />}
						disabled={!showTextControls}
						onClick={boldText}
						style={{ ...buttonStyles }}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="italic"
						size="large"
						icon={<ItalicOutlined style={{ ...iconStyles }} />}
						disabled={!showTextControls}
						onClick={italicText}
						style={{ ...buttonStyles }}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="underline"
						size="large"
						icon={<UnderlineOutlined style={{ ...iconStyles }} />}
						disabled={!showTextControls}
						onClick={underlineText}
						style={{ ...buttonStyles }}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="strikethrough"
						size="large"
						icon={<StrikethroughOutlined />}
						disabled={!showTextControls}
						onClick={strikethroughText}
						style={{ ...buttonStyles }}
					/>
				</Col>
			</Row>
			<Row gutter={[10, 10]} style={{ marginTop: 5 }}>
				<Col flex={1}>
					<Select
						placeholder="Select font"
						size="large"
						disabled={!showTextControls}
						style={{ width: '100%', padding: '5px 0' }}
						onChange={updateFont}
						open={fontSelectorOpen}
						onFocus={() => setFontSelectorOpen(true)}
						onBlur={() => setFontSelectorOpen(false)}
						value={selectedFont}
					>
						{fonts.map((font) => {
							return (
								<Option
									key={font}
									value={font}
									style={{ fontSize: 25, padding: 10, fontFamily: font }}
								>
									{font}
								</Option>
							);
						})}
					</Select>
				</Col>
				<Col flex={1} style={{ marginTop: 5 }}>
					<InputNumber
						min={1}
						max={100}
						defaultValue={30}
						onChange={updateTextFontSize}
						style={{ height: '40px' }}
						title="Font size"
						disabled={!showTextControls}
						value={fontSize}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="Text align left"
						size="large"
						icon={<AlignLeftOutlined style={{ ...alignmentIconStyles }} />}
						disabled={!showTextControls}
						onClick={() => updateTextAlign('left')}
						style={{ ...buttonStyles }}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="Text align center"
						size="large"
						icon={<AlignCenterOutlined style={{ ...alignmentIconStyles }} />}
						disabled={!showTextControls}
						onClick={() => updateTextAlign('center')}
						style={{ ...buttonStyles }}
					/>
				</Col>
				<Col flex={1}>
					<Button
						title="Text align right"
						size="large"
						icon={<AlignRightOutlined style={{ ...alignmentIconStyles }} />}
						disabled={!showTextControls}
						onClick={() => updateTextAlign('center')}
						style={{ ...buttonStyles }}
					/>
				</Col>
			</Row>
		</>
	);
};

export default TextControls;
