import {
	ArrowLeftOutlined,
	DeleteFilled,
	MinusOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Button, Image, Modal, Result, Space } from 'antd';
import { ICartItem, IState } from '../../interface';
import { getCartTotal, getPrice } from '../../utils/price';
import { useDispatch, useSelector } from 'react-redux';

import CartActions from '../../redux/CartRedux';
import CartEmpty from '../Icons/CartEmpty';
import Icon from '@ant-design/icons';
import StripeCheckout from '../../container/CheckoutFlow/StripeCheckout';
import TShirtDesignViewer from '../TShirtDesignViewer';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const CartEmptyIcon = () => <Icon component={CartEmpty} />;

export const ShoppingCart = () => {
	const history = useHistory();
	const cart = useSelector((state: IState) => state.cart);
	const canvas = useSelector((state: IState) => state.canvas);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const { items } = cart;
	const dispatch = useDispatch();
	const cartTotal = getCartTotal(items).toFixed(2);

	const addItemQuantity = (item: ICartItem) => {
		const { quantity } = item;

		dispatch(
			CartActions.updateCartItem({
				...item,
				quantity: quantity + 1,
			})
		);
	};

	const minusItemQuantity = (item: ICartItem) => {
		const { quantity } = item;

		dispatch(
			CartActions.updateCartItem({
				...item,
				quantity: quantity > 0 ? quantity - 1 : quantity,
			})
		);
	};

	const deleteCartItem = (item: ICartItem) => {
		dispatch(CartActions.removeCartItem(item));
	};

	const navigateToDesigner = () => {
		history.push({ pathname: '/design' });
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return items.length ? (
		<div className="h-screen bg-gray-300">
			<div className="py-12">
				<div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-3xl">
					<div className="md:flex">
						<div className="w-full p-4 px-5 py-5">
							<Space direction="horizontal">
								<Button
									type="primary"
									shape="circle"
									icon={<ArrowLeftOutlined />}
									onClick={() => history.goBack()}
								/>
								<h1 className="text-xl font-medium">Checkout</h1>
								<a href="https://stripe.com" target="_blank" rel="noreferrer">
									<Image
										src={
											require('../../assets/images/powered-by-stripe.svg')
												.default
										}
										preview={false}
										width={100}
										height={50}
									/>
								</a>
							</Space>
							{items.map((item: ICartItem, index: number) => {
								const { id, quantity, printBackSide, size, images } = item;
								return (
									<div
										className="flex justify-between items-center mt-6 pt-6"
										key={`${id}-${index}`}
									>
										<div className="flex items-center">
											<div className="flex flex-col items-center">
												{images[0] && (
													<Image
														src={images[0]}
														preview={false}
														width={50}
														fallback={
															require('../../assets/images/fallback_image.png')
																.default
														}
													/>
												)}
												<Button type="link" onClick={showModal}>
													View
												</Button>
											</div>
											<div className="flex flex-col ml-3">
												<span className="text-sm font-light text-gray-500">
													{quantity} X {size} TShirt
													<br />
													{printBackSide && 'Front & back print'}
												</span>
											</div>
										</div>
										<div className="flex justify-center items-center">
											<div className="pr-8 flex">
												<Button
													type="primary"
													shape="circle"
													icon={<MinusOutlined style={{ fontSize: 20 }} />}
													onClick={() => minusItemQuantity(item)}
												/>
												<span className="border h-8 w-10 rounded text-md text-center px-2 mx-2">
													{quantity}
												</span>
												<Button
													type="primary"
													shape="circle"
													icon={<PlusOutlined style={{ fontSize: 20 }} />}
													onClick={() => addItemQuantity(item)}
												/>
											</div>
											<div className="pr-8">
												<span className="text-lg font-medium">
													${getPrice(printBackSide)}
												</span>
											</div>
											<Button
												type="ghost"
												shape="circle"
												icon={<DeleteFilled style={{ color: 'red' }} />}
												onClick={() => deleteCartItem(item)}
											/>
											<div>
												<i className="fa fa-close text-xs font-medium"></i>
											</div>
										</div>
									</div>
								);
							})}
							<div className="flex justify-between items-center mt-6 pt-6 border-t">
								<div className="flex justify-center">
									<span className="text-lg font-medium text-gray-400 mr-1">
										Total
										<span className="text-xs font-medium text-gray-400 mr-1">
											{' '}
											(incluing tax)
										</span>
										:
									</span>
									<span className="text-lg font-bold text-gray-800">
										${cartTotal}
									</span>
								</div>
								<div className="flex items-end">
									<StripeCheckout />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
				centered
			>
				<TShirtDesignViewer canvasId={canvas.id} />
			</Modal>
		</div>
	) : (
		<Result
			icon={<CartEmptyIcon />}
			title="Cart is empty"
			extra={
				<Button type="primary" onClick={navigateToDesigner} key="design">
					Design Now
				</Button>
			}
		/>
	);
};

export default ShoppingCart;
