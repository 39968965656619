import firebase from 'firebase/app';

export interface ICartItem {
	id: string;
	name: string;
	quantity: number;
	price: number;
	color: string;
	size: string;
	printBackSide: boolean;
	images: string[];
}

export interface IState {
	canvas: ICanvasState;
	cart: ICartState;
	auth: IAuthState;
	loading: ILoadingState;
}

export enum TShirtSide {
	FRONT = 'FRONT',
	BACK = 'BACK',
}

export interface ICartState {
	items: ICartItem[];
	error: boolean;
	loading: boolean;
}

export interface ICanvasState {
	id: string;
	frontTShirtImage: string;
	backTShirtImage: string;
	tShirtSide: TShirtSide;
	printBackSide: boolean;
	tShirtColor: string;
	tShirtSize: any /*(string | number)[];*/;
	showTextControls: boolean;
	showImageControls: boolean;
	showGroupControls: boolean;
	isTourOpen: boolean;
	frontCanvasData: string;
	backCanvasData: string;
}

export interface ITShirtDesign {
	id: string;
	data: firebase.firestore.DocumentData;
}

export interface IAuthState {
	user?: IUser;
}

export interface IUser {
	displayName: string;
	email: string;
	emailVerified: boolean;
	uid: string;
	photoURL: string;
}

export interface ILoadingState {
	cartLoading: boolean;
	canvasLoading: boolean;
	authLoading: boolean;
}

export type FirebaseDocRef = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

export interface ICheckout {
	cart: {
		items: ICartItem[];
	};
	userId: string;
	customerEmail: string;
	checkoutId: string;
	canvasId: string;
}

export interface ISvgImage {
	svgurl: string;
	title: string;
	filesize: string;
}

export interface ISvgData {
	items: ISvgImage[];
	page: number;
	pages: number;
	count: number;
}

export enum OrderStatus {
	Pending = 'Pending',
	Processing = 'Processing',
	Delivered = 'Delivered',
}

export interface IOrderData {
	orderId: string;
	customerId: string;
	paymentStatus: OrderStatus;
	shipping: any;
	canvasData: FirebaseFirestore.DocumentData;
	items: any;
}

export interface ICanvasData {
	version: string;
	objects: Array<any>;
}

export interface IAddress {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postal: string;
	state: string;
}