import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';

const {
	REACT_APP_FIREBASE_APIKEY,
	REACT_APP_FIREBASE_AUTHDOMAIN,
	REACT_APP_FIREBASE_PROJECTID,
	REACT_APP_FIREBASE_STORAGEBUCKET,
	REACT_APP_FIREBASE_MESSAGINGSENDERID,
	REACT_APP_FIREBASE_APPID,
	REACT_APP_FIREBASE_MEASUREMENTID,
} = process.env;

const firebaseConfig = {
	apiKey: REACT_APP_FIREBASE_APIKEY,
	authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: REACT_APP_FIREBASE_PROJECTID,
	storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
	appId: REACT_APP_FIREBASE_APPID,
	measurementId: REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}
firebase.analytics();

export const firebaseAuth = firebase.auth;
export const firestore = firebase.firestore;
export const storage = firebase.storage;
