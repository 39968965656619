import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import { IState } from '../../interface';
import Tour from 'reactour';

const steps = [
	{
		selector: '#tour-step1',
		content:
			"Welcome to CustomPrinting, let's get familiar with the designer. This is your canvas where you can create your designs",
	},
	{
		selector: '#tour-step2',
		content: 'This is your canvas where you can create your designs',
	},
	{
		selector: '#tour-step5',
		content:
			'Select preferred t-shirt size and option to print back side as well',
	},
	{
		selector: '#tour-step6',
		content: 'Pick your favorite t-shrit colors',
	},
	{
		selector: '#tour-step4',
		content: 'You can also add images or search for one',
	},
	{
		selector: '#tour-step3',
		content:
			"Add text into the canvas, change it's font, font-size, color and orientation",
	},

	{
		selector: '#tour-step7',
		content:
			'Use these controls to group/ungroup items in the canvas or delete them',
	},
	{
		selector: '#tour-step8',
		content:
			"Accidentally deleted something? don't worry, use undo and redo controls",
	},
	{
		selector: '#tour-step9',
		content: 'Once happy with the design, add it to the cart for checkout',
	},
];

export const Onboarding = () => {
	const isTourOpen = useSelector((state: IState) => state.canvas.isTourOpen);
	const dispatch = useDispatch();

	const finishTour = () => {
		dispatch(CanvasActions.finishTour());
	};

	return <Tour steps={steps} isOpen={isTourOpen} onRequestClose={finishTour} />;
};

export default Onboarding;
