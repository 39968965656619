import { Grid, Layout, Result } from 'antd';

import { Helmet } from 'react-helmet';
import Icon from '@ant-design/icons';
import NotSupported from '../components/Icons/NotSupported';
import Onboarding from '../components/Onboarding/Onboarding';
import TShirtDesigner from '../container/Designer/TShirtDesigner';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const NotSupportedIcon = () => <Icon component={NotSupported} />;

const Designer = () => {
	const screens = useBreakpoint();

	return (
		<Content style={{ padding: '0 25px', marginTop: 70 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Designer</title>
			</Helmet>
			{process.env.NODE_ENV === 'development' || screens.lg ? (
				<TShirtDesigner />
			) : (
				<Result
					icon={<NotSupportedIcon />}
					title="Custom designer is not supported on small screen devices yet!"
					subTitle="Please swtich to a device with larger screen size"
				/>
			)}
			<Onboarding />
		</Content>
	);
};

export default Designer;
