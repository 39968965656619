import Location from '../Location';

export const OurLocation = () => {
	return (
		<section>
			<div className="py-16 radius-for-skewed">
				<div className="container mx-auto px-4">
					<div className="max-w-xl mx-auto mb-10 text-center">
						<h2 className="text-purple-800 m-5 text-4xl lg:text-5xl font-bold font-heading">
							Our Location
						</h2>
						<span className="text-pink-600 font-bold">
							La Gran Plaza de Fort Worth <br /> 4200 South Freeway,{' '}
							<br /> Fort Worth, TX 76115
						</span>
					</div>
					<div className="flex flex-wrap">
						<div className="w-full p-2 bg-white rounded-lg shadow">
							<Location />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurLocation;
