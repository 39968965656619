import 'animate.css/animate.compat.css';

import Features from '../components/Home/Features';
import { Helmet } from 'react-helmet';
import Hero from '../components/Home/Hero';
import { IState } from '../interface';
import LoginRegister from '../components/Home/LoginRegister';
import OurDesigns from '../components/Home/OurDesigns';
import OurLocation from '../components/Home/OurLocation';
import Pricing from '../components/Home/Pricing';
import { useSelector } from 'react-redux';

export const Home = () => {
	const user = useSelector((state: IState) => state.auth.user);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Home</title>
			</Helmet>
			<Hero />
			<Features />
			<OurDesigns limit={6} />
			<Pricing />
			<OurLocation />
			{!user && <LoginRegister />}
		</>
	);
};

export default Home;
