import {
	CreatedActions,
	DefaultActionTypes,
	createActions,
	createReducer,
} from 'reduxsauce';

import { ILoadingState } from '../interface';

const { Types, Creators }: CreatedActions = createActions({
	setCartLoading: ['data'],
	setCanvasLoading: ['data'],
	setAuthLoading: ['data'],
});

export const LoadingTypes: DefaultActionTypes = Types;

export const LoadingSelectors = {
	canvasLoading: (state: { Loading: ILoadingState }) =>
		state.Loading.canvasLoading,
	cartLoading: (state: { Loading: ILoadingState }) => state.Loading.cartLoading,
};

export const INITIAL_STATE: ILoadingState = {
	cartLoading: false,
	canvasLoading: false,
	authLoading: false,
};

const setCartLoading = (
	state = INITIAL_STATE,
	action: { data: boolean }
): ILoadingState => {
	const { data } = action;

	return { ...state, cartLoading: data };
};

const setCanvasLoading = (
	state = INITIAL_STATE,
	action: { data: boolean }
): ILoadingState => {
	const { data } = action;

	return { ...state, canvasLoading: data };
};

const setAuthLoading = (
	state = INITIAL_STATE,
	action: { data: boolean }
): ILoadingState => {
	const { data } = action;

	return { ...state, authLoading: data };
};

const HANDLERS = {
	[Types.SET_CART_LOADING]: setCartLoading,
	[Types.SET_CANVAS_LOADING]: setCanvasLoading,
	[Types.SET_AUTH_LOADING]: setAuthLoading,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default Creators;
