import { Button, Modal, Table, Tabs } from 'antd';

import { QuestionCircleTwoTone } from '@ant-design/icons';
import { tshirtSizeData } from '../../data';
import { useState } from 'react';

const { TabPane } = Tabs;

const TShirtSizeInformation = () => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Button
				type="link"
				size="large"
				onClick={() => setVisible(true)}
				icon={<QuestionCircleTwoTone />}
				title="TShirt Size Information"
			/>
			<Modal
				title="TShirt Size Information"
				centered
				visible={visible}
				onCancel={() => setVisible(false)}
				okButtonProps={{ hidden: true }}
				cancelText={'Close'}
				width={800}
			>
				<Tabs defaultActiveKey="0">
					{tshirtSizeData.map((item, index) => (
						<TabPane tab={item.type} key={index}>
							<Table
								columns={item.columns}
								// @ts-ignore
								dataSource={item.data}
								pagination={false}
								size={'middle'}
							/>
						</TabPane>
					))}
				</Tabs>
			</Modal>
		</>
	);
};

export default TShirtSizeInformation;
