import { ILineOptions } from 'fabric/fabric-impl';

import { fabric } from 'fabric';
import { deleteIcon } from '../components/Icons';

/**
 * Should objects be aligned by a bounding box?
 * [Bug] Scaled objects sometimes can not be aligned by edges
 *
 */
export const initAligningGuidelines = (canvas: fabric.Canvas) => {
	let ctx = canvas.getSelectionContext(),
		aligningLineOffset = 5,
		aligningLineMargin = 4,
		aligningLineWidth = 1,
		aligningLineColor = 'rgb(0,255,0)',
		viewportTransform = canvas.viewportTransform,
		zoom = 1;

	const drawVerticalLine = (coords: ILineOptions) => {
		drawLine(
			coords.x1! + 0.5,
			coords.y1! > coords.y2! ? coords.y2! : coords.y1!,
			coords.x1! + 0.5,
			coords.y2! > coords.y1! ? coords.y2! : coords.y1!
		);
	};

	const drawHorizontalLine = (coords: ILineOptions) => {
		drawLine(
			coords.x1! > coords.x2! ? coords.x2! : coords.x1!,
			coords.y1! + 0.5,
			coords.x2! > coords.x1! ? coords.x2! : coords.x1!,
			coords.y1! + 0.5
		);
	};

	const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
		ctx.save();
		ctx.lineWidth = aligningLineWidth;
		ctx.strokeStyle = aligningLineColor;
		ctx.beginPath();
		if (viewportTransform) {
			ctx.moveTo(
				(x1 + viewportTransform[4]) * zoom,
				(y1 + viewportTransform[5]) * zoom
			);
			ctx.lineTo(
				(x2 + viewportTransform[4]) * zoom,
				(y2 + viewportTransform[5]) * zoom
			);
		}
		ctx.stroke();
		ctx.restore();
	};

	const isInRange = (value1: number, value2: number) => {
		value1 = Math.round(value1);
		value2 = Math.round(value2);
		for (
			let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin;
			i <= len;
			i++
		) {
			if (i === value2) {
				return true;
			}
		}
		return false;
	};

	const verticalLines: ILineOptions[] = [],
		horizontalLines: ILineOptions[] = [];

	canvas.on('mouse:down', () => {
		viewportTransform = canvas.viewportTransform;
		zoom = canvas.getZoom();
	});

	canvas.on('object:moving', (e) => {
		let activeObject = e.target;
		if (!activeObject || !viewportTransform) return;

		let canvasObjects = canvas.getObjects();
		let activeObjectCenter = activeObject.getCenterPoint(),
			activeObjectLeft = activeObjectCenter.x,
			activeObjectTop = activeObjectCenter.y,
			activeObjectBoundingRect = activeObject.getBoundingRect(),
			activeObjectHeight =
				activeObjectBoundingRect.height / viewportTransform[3],
			activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
			horizontalInTheRange = false,
			verticalInTheRange = false,
			transform = canvas.viewportTransform;

		if (!transform) return;

		// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into consts,
		// but we're not doing it here for perf. reasons -- as this a const that's invoked on every mouse move

		for (let i = canvasObjects.length; i--; ) {
			if (canvasObjects[i] === activeObject) continue;

			const objectCenter = canvasObjects[i].getCenterPoint(),
				objectLeft = objectCenter.x,
				objectTop = objectCenter.y,
				objectBoundingRect = canvasObjects[i].getBoundingRect(),
				objectHeight = objectBoundingRect.height / viewportTransform[3],
				objectWidth = objectBoundingRect.width / viewportTransform[0];

			// snap by the horizontal center line
			if (isInRange(objectLeft, activeObjectLeft)) {
				verticalInTheRange = true;
				verticalLines.push({
					x1: objectLeft,
					y1:
						objectTop < activeObjectTop
							? objectTop - objectHeight / 2 - aligningLineOffset
							: objectTop + objectHeight / 2 + aligningLineOffset,
					y2:
						activeObjectTop > objectTop
							? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
							: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(objectLeft, activeObjectTop),
					'center',
					'center'
				);
			}

			// snap by the left edge
			if (
				isInRange(
					objectLeft - objectWidth / 2,
					activeObjectLeft - activeObjectWidth / 2
				)
			) {
				verticalInTheRange = true;
				verticalLines.push({
					x1: objectLeft - objectWidth / 2,
					y1:
						objectTop < activeObjectTop
							? objectTop - objectHeight / 2 - aligningLineOffset
							: objectTop + objectHeight / 2 + aligningLineOffset,
					y2:
						activeObjectTop > objectTop
							? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
							: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(
						objectLeft - objectWidth / 2 + activeObjectWidth / 2,
						activeObjectTop
					),
					'center',
					'center'
				);
			}

			// snap by the right edge
			if (
				isInRange(
					objectLeft + objectWidth / 2,
					activeObjectLeft + activeObjectWidth / 2
				)
			) {
				verticalInTheRange = true;
				verticalLines.push({
					x1: objectLeft + objectWidth / 2,
					y1:
						objectTop < activeObjectTop
							? objectTop - objectHeight / 2 - aligningLineOffset
							: objectTop + objectHeight / 2 + aligningLineOffset,
					y2:
						activeObjectTop > objectTop
							? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
							: activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(
						objectLeft + objectWidth / 2 - activeObjectWidth / 2,
						activeObjectTop
					),
					'center',
					'center'
				);
			}

			// snap by the vertical center line
			if (isInRange(objectTop, activeObjectTop)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y1: objectTop,
					x1:
						objectLeft < activeObjectLeft
							? objectLeft - objectWidth / 2 - aligningLineOffset
							: objectLeft + objectWidth / 2 + aligningLineOffset,
					x2:
						activeObjectLeft > objectLeft
							? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
							: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(activeObjectLeft, objectTop),
					'center',
					'center'
				);
			}

			// snap by the top edge
			if (
				isInRange(
					objectTop - objectHeight / 2,
					activeObjectTop - activeObjectHeight / 2
				)
			) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y1: objectTop - objectHeight / 2,
					x1:
						objectLeft < activeObjectLeft
							? objectLeft - objectWidth / 2 - aligningLineOffset
							: objectLeft + objectWidth / 2 + aligningLineOffset,
					x2:
						activeObjectLeft > objectLeft
							? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
							: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(
						activeObjectLeft,
						objectTop - objectHeight / 2 + activeObjectHeight / 2
					),
					'center',
					'center'
				);
			}

			// snap by the bottom edge
			if (
				isInRange(
					objectTop + objectHeight / 2,
					activeObjectTop + activeObjectHeight / 2
				)
			) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y1: objectTop + objectHeight / 2,
					x1:
						objectLeft < activeObjectLeft
							? objectLeft - objectWidth / 2 - aligningLineOffset
							: objectLeft + objectWidth / 2 + aligningLineOffset,
					x2:
						activeObjectLeft > objectLeft
							? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
							: activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				activeObject.setPositionByOrigin(
					new fabric.Point(
						activeObjectLeft,
						objectTop + objectHeight / 2 - activeObjectHeight / 2
					),
					'center',
					'center'
				);
			}
		}

		if (!horizontalInTheRange) {
			horizontalLines.length = 0;
		}

		if (!verticalInTheRange) {
			verticalLines.length = 0;
		}
	});

	canvas.on('before:render', () => {
		canvas.clearContext(ctx);
	});

	canvas.on('after:render', () => {
		for (let i = verticalLines.length; i--; ) {
			drawVerticalLine(verticalLines[i]);
		}
		for (let i = horizontalLines.length; i--; ) {
			drawHorizontalLine(horizontalLines[i]);
		}

		verticalLines.length = horizontalLines.length = 0;
	});

	canvas.on('mouse:up', () => {
		verticalLines.length = horizontalLines.length = 0;
		canvas.renderAll();
	});

	fabric.Object.prototype.set({
		transparentCorners: false,
		borderColor: '#fdc7d7',
		cornerColor: '#fdc7d7',
		cornerStyle: 'circle',
	});

	// @ts-ignore
	fabric.Object.prototype.controls.deleteControl = new fabric.Control({
		x: 0.5,
		y: -0.5,
		offsetX: -15,
		offsetY: -30,
		cursorStyle: 'pointer',
		mouseUpHandler: () => deleteSelectedObject(canvas),
		render: createSvgIcon(deleteIcon),
		cornerSize: 24,
	});
};

export const setupCanvasBoundary = (
	canvas: fabric.Canvas,
	dimensionText: fabric.Text
) => {
	canvas.on('object:moving', function (e: any) {
		const obj = e.target;
		// if object is too big ignore
		if (
			obj.currentHeight > obj.canvas.height ||
			obj.currentWidth > obj.canvas.width
		) {
			return;
		}
		obj.setCoords();
		// top-left  corner
		if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
			obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
			obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
		}
		// bot-right corner
		if (
			obj.getBoundingRect().top + obj.getBoundingRect().height >
				obj.canvas.height ||
			obj.getBoundingRect().left + obj.getBoundingRect().width >
				obj.canvas.width
		) {
			obj.top = Math.min(
				obj.top,
				obj.canvas.height -
					obj.getBoundingRect().height +
					obj.top -
					obj.getBoundingRect().top
			);
			obj.left = Math.min(
				obj.left,
				obj.canvas.width -
					obj.getBoundingRect().width +
					obj.left -
					obj.getBoundingRect().left
			);
		}

		hideDimensionText(dimensionText);
	});
};

export const createSvgIcon = (icon: any) => {
	const img = document.createElement('img');
	img.src = icon;
	return (ctx: any, left: number, top: number, fabricObject: any) => {
		const size = 25;
		ctx.save();
		ctx.translate(left, top);
		ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
		ctx.drawImage(img, -size / 2, -size / 2, size, size);
		ctx.restore();
	};
};

export const deleteSelectedObject = (canvas: fabric.Canvas) => {
	const activeObjects: fabric.Object[] = canvas.getActiveObjects();
	activeObjects.forEach((activeObject: fabric.Object) => {
		canvas.remove(activeObject);
	});
	canvas.discardActiveObject().renderAll();
};

export const cloneObject = (canvas: fabric.Canvas) => {
	const activeObjects: fabric.Object[] = canvas.getActiveObjects();
	activeObjects.forEach((activeObject: fabric.Object) => {
		activeObject.clone((object: fabric.Object) => {
			const { top = 0, left = 0 } = object;
			object.set('top', top + 10);
			object.set('left', left || 0 + 10);

			const clonedObject = canvas.add(object) as fabric.Canvas;
			clonedObject.setActiveObject(object);
		});
	});
	canvas.renderAll();
};

export const cropImage = (canvas: fabric.Canvas) => {
	const activeObject: fabric.Object = canvas.getActiveObject();

	// if (activeObject && activeObject.type === 'image') {
	if (activeObject) {
		const {
			width = 0,
			height = 0,
			scaleX = 1,
			scaleY = 1,
			left = 0,
			top = 0,
		} = activeObject;
		const el = new fabric.Rect({
			fill: 'rgba(0,0,0,0.6)',
			stroke: '#ccc',
			strokeDashArray: [2, 2],
			opacity: 1,
			width: width * scaleX,
			height: height * scaleY,
			left,
			top,
			borderColor: 'red',
			cornerColor: 'red',
			hasRotatingPoint: false,
		});

		//??
		canvas.add(el);
		canvas.setActiveObject(el);
		el.on('deselected', function () {
			canvas.renderAll();
			doCrop(el, activeObject, canvas);
		});
	}
	canvas.renderAll();
};

function doCrop(el: any, object: any, canvas: fabric.Canvas) {
	const newImgCrop = el.getBoundingRect();

	object.set({
		cropX: newImgCrop.left,
		cropY: newImgCrop.top,
		width: newImgCrop.width / object.scaleX,
		height: newImgCrop.height / object.scaleY,
	});
	canvas.remove(el);
	canvas.renderAll();
}

export const keyDownHandler = (event: KeyboardEvent, canvas: fabric.Canvas) => {
	// const activeObject = canvas.getActiveObject();
	// @ts-ignore
	// const { isEditing } = activeObject || {};
	const ctrlDown = event.ctrlKey || event.metaKey;
	const shiftDown = event.shiftKey;
	const zDown = event.key === 'z';
	// const aDown = event.key === 'a';

	if (ctrlDown && shiftDown && zDown) {
		redo(canvas);
	} else if (ctrlDown && zDown) {
		undo(canvas);
	}
	// else if (!isEditing && event.key === 'Backspace') {
	// 	deleteSelectedObject(canvas);
	// }
	// else if (ctrlDown && aDown) {
	// 	const selection = new fabric.ActiveSelection(canvas.getObjects(), {
	// 		canvas: canvas,
	// 	});
	// 	canvas.setActiveObject(selection);
	// 	canvas.requestRenderAll();
	// }
};

export const undo = (canvas: fabric.Canvas) => {
	// @ts-ignore
	canvas.undo();
};

export const redo = (canvas: fabric.Canvas) => {
	// @ts-ignore
	canvas.redo();
};

export const getCanvasDataUrl = (
	canvas: fabric.Canvas,
	tShirtColor: string
) => {
	canvas.backgroundColor = tShirtColor;
	const image = canvas.toDataURL({
		format: 'png',
		multiplier: 3,
		enableRetinaScaling: true,
	});

	canvas.backgroundColor = undefined;

	return image;
};

export const downloadCanvasImage = (
	canvas: fabric.Canvas,
	tShirtColor: string
) => {
	canvas.backgroundColor = undefined;
	const image = canvas.toDataURL({
		format: 'png',
		multiplier: 3,
		enableRetinaScaling: true,
	});
	var link = document.createElement('a');
	link.style.display = 'none';
	link.href = image;
	link.download = `CP_${tShirtColor}_${new Date().toLocaleDateString()}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const hideDimensionText = (dimensionText: fabric.Text) => {
	dimensionText.visible = false;
};

export const showDimensionText = (dimensionText: fabric.Text) => {
	dimensionText.visible = true;
};
