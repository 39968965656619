import { useEffect, useRef, useState } from 'react';

import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken =
	'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';

const Location = () => {
	const [map, setMap] = useState<mapboxgl.Map>();
	const mapContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const initializeMap = () => {
			const map = new mapboxgl.Map({
				container: mapContainer.current || '',
				style: 'mapbox://styles/mapbox/streets-v11',
				center: [-97.3220803, 32.6849588],
				zoom: 10,
			});

			var popup = new mapboxgl.Popup({
				closeOnClick: false,
				offset: 25,
			}).setHTML(
				'<p style="margin: 10px">La Gran Plaza de Fort Worth <br/> 4200 South Freeway <br/> Fort Worth, TX 76115</p>'
			);

			// create DOM element for the marker
			var el = document.createElement('div');
			el.id = 'marker';

			// create the marker
			new mapboxgl.Marker(el)
				.setLngLat([-97.3220803, 32.6849588])
				.setPopup(popup);
			// .addTo(map)
			// .togglePopup();

			map.on('load', () => {
				setMap(map);
			});
		};

		if (!map) {
			initializeMap();
		}
	}, [map]);

	return (
		<>
			<div
				// @ts-ignore
				ref={(el) => (mapContainer.current = el)}
				className="map-container"
			/>
		</>
	);
};

export default Location;
