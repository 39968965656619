import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import { firebaseAuth } from '../../config/firebase';

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/',
	callbacks: {
		signInSuccessWithAuthResult: () => false,
	},
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		// firebase.auth.EmailAuthProvider.PROVIDER_ID,
	],
};

export const GoogleLogin = () => {
	return (
		<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth()} />
	);
};

export default GoogleLogin;
