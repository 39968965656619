import { Button, Col, Modal, Row } from 'antd';
import {
	CloseCircleFilled,
	DeleteFilled,
	GroupOutlined,
	StepBackwardFilled,
	StepForwardFilled,
	UngroupOutlined,
} from '@ant-design/icons';
import { IState, TShirtSide } from '../../interface';
import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import { deleteSelectedObject } from '../../utils/fabricCanvasHelper';
import { useState } from 'react';

interface ICanvasElement {
	canvas: fabric.Canvas;
}

const ElementControls = (props: ICanvasElement) => {
	const [modalVisible, setModalVisible] = useState(false);
	const dispatch = useDispatch();

	const { canvas } = props;
	const canvasState = useSelector((state: IState) => state.canvas);
	const {
		tShirtSide,
		showTextControls,
		showImageControls,
		showGroupControls,
	} = canvasState;
	const textOrImageControlsEnabled =
		showGroupControls || showTextControls || showImageControls;

	const onShowModal = () => {
		setModalVisible(true);
	};

	const onModalCancel = () => {
		setModalVisible(false);
	};

	const onClearCanvas = () => {
		canvas.clear();

		let data = {};
		if (tShirtSide === TShirtSide.FRONT) {
			data = {
				fronCanvasData: '',
			};
		} else {
			data = { backCanvasData: '' };
		}

		dispatch(CanvasActions.saveCanvasState({ ...data }));
		setModalVisible(false);
	};

	const sendSelectedObjectBack = () => {
		const activeObjects: fabric.Object[] = canvas.getActiveObjects();
		activeObjects.forEach((activeObject: fabric.Object) => {
			canvas.sendToBack(activeObject);
		});
	};

	const bringSelectedObjectFront = () => {
		const activeObjects: fabric.Object[] = canvas.getActiveObjects();
		activeObjects.forEach((activeObject: fabric.Object) => {
			canvas.bringToFront(activeObject);
		});
	};

	const groupObjects = () => {
		if (!canvas.getActiveObject()) {
			return;
		}
		if (canvas.getActiveObject().type !== 'activeSelection') {
			return;
		}

		const activeObject = canvas.getActiveObject() as fabric.ActiveSelection;
		activeObject.toGroup();

		canvas.requestRenderAll();
	};

	const ungroupObjects = () => {
		if (!canvas.getActiveObject()) {
			return;
		}
		if (canvas.getActiveObject().type !== 'group') {
			return;
		}

		const activeObject = canvas.getActiveObject() as fabric.ActiveSelection;
		activeObject.toActiveSelection();

		canvas.requestRenderAll();
	};

	return (
		<div id="tour-step7">
			<Row>
				{/* <Col flex={1}>
					<Button
						icon={
							<CopyOutlined
								style={{
									fontSize: '20px',
									color: textOrImageControlsEnabled ? 'green' : '#00000040',
								}}
							/>
						}
						onClick={() => cloneObject(canvas)}
						style={{ height: '55px' }}
						disabled={!textOrImageControlsEnabled}
					>
						<br />
						Clone
					</Button>
				</Col> */}
				<Col flex={1}>
					<Button
						icon={<GroupOutlined style={{ fontSize: '20px' }} />}
						onClick={groupObjects}
						style={{ height: '55px' }}
						disabled={!showGroupControls}
					>
						<br />
						Group
					</Button>
				</Col>
				<Col flex={1}>
					<Button
						icon={<UngroupOutlined style={{ fontSize: '20px' }} />}
						onClick={ungroupObjects}
						style={{ height: '55px' }}
						disabled={!showGroupControls}
					>
						<br />
						Ungroup
					</Button>
				</Col>
				<Col flex={1}>
					<Button
						type="dashed"
						icon={<DeleteFilled style={{ fontSize: '20px' }} />}
						onClick={() => deleteSelectedObject(canvas)}
						danger
						block
						style={{ height: '55px' }}
						disabled={!textOrImageControlsEnabled}
					>
						<br />
						Delete
					</Button>
				</Col>
				<Col flex={1}>
					<Button
						type="dashed"
						icon={<CloseCircleFilled style={{ fontSize: '20px' }} />}
						onClick={onShowModal}
						danger
						block
						style={{ height: '55px' }}
					>
						<br />
						Delete all
					</Button>
				</Col>
			</Row>
			<Row gutter={[0, 8]}>
				{showImageControls && (
					<>
						<Col flex={1}>
							<Button
								icon={
									<StepBackwardFilled
										style={{ fontSize: '20px', color: '#08c' }}
									/>
								}
								onClick={sendSelectedObjectBack}
								block
								style={{ height: '55px' }}
							>
								<br />
								Send back
							</Button>
						</Col>
						<Col flex={1}>
							<Button
								icon={
									<StepForwardFilled
										style={{ fontSize: '20px', color: '#08c' }}
									/>
								}
								onClick={bringSelectedObjectFront}
								block
								style={{ height: '55px' }}
							>
								<br />
								Bring front
							</Button>
						</Col>
					</>
				)}
			</Row>
			<Modal
				title="Clear canvas"
				visible={modalVisible}
				onOk={onClearCanvas}
				onCancel={onModalCancel}
			>
				<p>Are you sure you want to clear the canvas?</p>
			</Modal>
		</div>
	);
};

export default ElementControls;
