import {
	Avatar,
	Badge,
	Button,
	Grid,
	Layout,
	Menu,
	Space,
	Typography,
} from 'antd';
import {
	ExperimentOutlined,
	ShoppingCartOutlined,
	ShoppingOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AuthActions from '../../redux/AuthRedux';
import CanvasActions from '../../redux/CanvasRedux';
import { IState } from '../../interface';
import SubMenu from 'antd/lib/menu/SubMenu';
import { firebaseAuth } from '../../config/firebase';

const { Header } = Layout;
const { Title } = Typography;
const { useBreakpoint } = Grid;

const AppHeader = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const screens = useBreakpoint();

	const cart = useSelector((state: IState) => state.cart);
	const user = useSelector((state: IState) => state.auth.user);

	const { email, displayName, photoURL } = user || {};

	const logout = async () => {
		await firebaseAuth().signOut();
		dispatch(AuthActions.logout());
	};

	const startTutorial = () => {
		dispatch(CanvasActions.startTour());
	};

	return (
		<Header
			style={{
				position: 'fixed',
				zIndex: 11,
				width: '100%',
				height: '66px',
				boxShadow: 'none',
			}}
		>
			<Menu
				mode="horizontal"
				theme="light"
				defaultSelectedKeys={[location.pathname]}
			>
				<Menu.Item key="logo">
					<Link to="/" className="nav-text">
						<img
							src={require('../../assets/images/logo.webp').default}
							alt="logo"
							width={64}
							height={64}
						/>
					</Link>
				</Menu.Item>
				<Menu.Item key="/">
					<Link to="/" className="nav-text">
						Home
					</Link>
				</Menu.Item>
				<Menu.Item key="/designs">
					<Link to="/designs" className="nav-text">
						Our designs
					</Link>
				</Menu.Item>
				<Menu.Item key="/design">
					<Link to="/design" className="nav-text">
						Design Lab
					</Link>
				</Menu.Item>
				<SubMenu
					key="account"
					style={{ float: 'right' }}
					icon={<UserOutlined style={{ fontSize: 24 }} />}
				>
					{user ? (
						<>
							<Menu.Item key="avatar" style={{ margin: 10 }}>
								<Space direction="horizontal" size="large">
									<Avatar
										size="large"
										src={photoURL}
										icon={<UserOutlined />}
										style={{ backgroundColor: '#8fe3d9' }}
									/>
									<Title level={5}>{displayName || email}</Title>
								</Space>
							</Menu.Item>
							<Menu.Item key="logout" style={{ margin: 10 }} title="Logout">
								<Button type="link" block onClick={logout}>
									Logout
								</Button>
							</Menu.Item>
						</>
					) : (
						<>
							<Menu.Item key="login">
								<Link to="/login">Login</Link>
							</Menu.Item>
							<Menu.Item key="register">
								<Link to="/register">Register</Link>
							</Menu.Item>
						</>
					)}
				</SubMenu>
				{user && (
					<>
						<Menu.Item
							key="/checkout"
							style={{ float: 'right' }}
							title={'Cart'}
						>
							<Link to="/checkout">
								<Badge count={cart.items.length}>
									<ShoppingCartOutlined style={{ fontSize: 24 }} />
								</Badge>
							</Link>
						</Menu.Item>
						<Menu.Item
							key="/orders"
							className="nav-text"
							style={{ float: 'right' }}
							title={'Orders'}
						>
							<Link to="/orders">
								<ShoppingOutlined style={{ fontSize: 24 }} />
							</Link>
						</Menu.Item>
					</>
				)}
				{location.pathname === '/design' && screens.lg && (
					<Menu.Item
						key="tutorial"
						style={{ float: 'right' }}
						title={'Take a Tour'}
						onClick={startTutorial}
					>
						<ExperimentOutlined style={{ fontSize: 24 }} /> Take a Tour
					</Menu.Item>
				)}
			</Menu>
		</Header>
	);
};

export default AppHeader;
