import { Button, Form, Input, message, Modal } from 'antd';

import { firebaseAuth } from '../../config/firebase';
import { useState } from 'react';
import { useHistory } from 'react-router';

export const ForgotPasswordForm = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const sendResetPasswordLink = async (values: any) => {
		setLoading(true);
		const { email } = values;

		try {
			await firebaseAuth().sendPasswordResetEmail(email);

			Modal.info({
				title: 'Reset password link has been sent to your email address',
				content: 'Please follow instructions to reset your password.',
				okText: 'Back to login',
				onOk: () => history.push("/login"),
			});
		} catch (error) {
			message.error('Failed to send reset password link, please try again!');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="w-full flex flex-wrap">
			<div className="w-full md:w-1/2 flex flex-col">
				<div className="bg-white container mx-auto px-4 h-full">
					<div className="flex content-center items-center justify-center h-full">
						<div className="bg-gray-900w-full w-full lg:w-8/12 px-4 sm:mt-20">
							<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
								<div className="flex-auto px-10 py-10">
									<Form layout="vertical" onFinish={sendResetPasswordLink}>
										<div className="relative w-full mb-3">
											<Form.Item
												name="email"
												label="Email"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														type: 'email',
														message: 'The input is not valid E-mail!',
													},
												]}
											>
												<Input type="email" placeholder="Email" allowClear />
											</Form.Item>
										</div>
										<div className="text-center mt-6">
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													loading={loading}
												>
													Reset password
												</Button>
											</Form.Item>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-1/2 shadow-2xl">
				<img
					className="object-cover w-full hidden md:block"
					src={require('../../assets/images/forgot_password.svg').default}
					alt="register-tshirt"
				/>
			</div>
		</div>
	);
};

export default ForgotPasswordForm;
