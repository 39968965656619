import { Helmet } from 'react-helmet';
import { IState } from '../interface';
import { Layout } from 'antd';
import RegisterForm from '../components/Register';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Content } = Layout;

export const Register = () => {
	const user = useSelector((state: IState) => state.auth.user);
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.push('/');
		}
	}, [history, user]);

	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Register</title>
			</Helmet>
			<RegisterForm />
		</Content>
	);
};

export default Register;
