import { Link } from 'react-router-dom';

export const LoginRegister = () => {
	return (
		<section>
			<div className="flex flex-wrap">
				<div className="pt-6 lg:pt-16 pb-6 w-full bg-purple-900">
					<div className="max-w-md mx-auto">
						<div>
							<div className="lg:block w-full">
								<div
									className="bottom-0 inset-x-0 mx-auto mb-12 max-w-xl text-center"
									style={{ zIndex: 10 }}
								>
									<img
										className="lg:max-w-xl mx-auto"
										src={require('../../assets/images/register.svg').default}
										alt=""
									/>
									<h2 className="m-4 text-2xl text-white font-bold">
										Create a new account to get started
									</h2>
									<Link
										className="inline-block w-full lg:w-auto py-2 px-6 leading-loose text-white font-semibold bg-pink-600 border-2 border-pink-400 hover:border-pink-500 hover:text-white rounded-l-xl rounded-t-xl transition duration-200"
										to="/register"
									>
										Get Started
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LoginRegister;
