import './TShirtCanvas.css';

import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { Image } from 'antd';
import { fabric } from 'fabric';
import { useWindowSize } from '../../hooks/useWindowSize';

interface ITShirtCanvasProps {
	data?: string;
	id: string;
	backgroundImage: string;
	selectedSize: string | number;
	selectedColor: string;
}

const BannerTShirtCanvas = (props: ITShirtCanvasProps) => {
	const size = useWindowSize();
	const canvas = useRef<any>();
	const imageDivRef = useRef<any>();
	const { id, data, backgroundImage, selectedColor } = props;

	useEffect(() => {
		canvas.current = new fabric.StaticCanvas(id, {
			preserveObjectStacking: true,
			width: 500,
			height: 700,
		});

		canvas.current.backgroundColor = undefined;

		if (data) {
			canvas.current.loadFromJSON(data, () => {
				canvas.current.renderAll();
			});
		}
	}, [data, id]);

	const updateCanvasDimensions = useCallback(() => {
		const { width } = getImageDimensions();
		const ratio = canvas.current?.getWidth() / canvas.current?.getHeight();
		const scale = width / canvas.current?.getWidth();
		const zoom = canvas.current?.getZoom() * scale;

		canvas.current?.setDimensions(
			{
				width,
				height: width / ratio,
			},
			{
				cssOnly: false,
			}
		);

		canvas.current?.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
		canvas.current?.renderAll();
	}, []);

	useLayoutEffect(() => {
		updateCanvasDimensions();
	}, [canvas, size, updateCanvasDimensions]);

	const getImageDimensions = () => {
		const { width, height } = imageDivRef.current.getBoundingClientRect();

		return {
			width: width / 2.6,
			height: height / 2,
		};
	};

	return (
		<>
			<div className="tshirt">
				<div ref={imageDivRef}>
					<Image
						style={{ backgroundColor: selectedColor }}
						preview={false}
						src={backgroundImage}
						alt="tshirt"
						onLoad={updateCanvasDimensions}
					/>
				</div>
				<div className="home-droparea">
					<canvas id={id}></canvas>
				</div>
			</div>
		</>
	);
};

export default BannerTShirtCanvas;
