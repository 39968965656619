import { firebaseAuth, firestore } from '../config/firebase';
import { useEffect, useState } from 'react';

import { message } from 'antd';

export const useDesign = (canvasId: string) => {
	const [canvasData, setCanvasData] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);

	const user = firebaseAuth().currentUser;

	useEffect(() => {
		async function getDesignData() {
			try {
				const canvasRef = firestore()
					.doc(`canvas/${user?.uid}`)
					.collection('data')
					.doc(canvasId);

				const doc = await canvasRef.get();
				const canvasData = doc.data();

				setCanvasData(canvasData);
				setLoading(false);
			} catch (error) {
				setCanvasData(undefined);
				setLoading(false);

				message.error('Something went wrong!');
			}
		}

		getDesignData();
	}, [user?.uid, canvasId]);

	return { canvasData, loading };
};
