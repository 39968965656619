export const OrderPlaced = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="a658aab1-b68e-4d2a-94f7-9a6e4abf15c7"
			data-name="Layer 1"
			width="100%"
			height="100%"
			viewBox="0 0 816.21532 621"
		>
			<path
				d="M518.50083,268.28111h-2.97759V186.71075A47.2107,47.2107,0,0,0,468.31258,139.5H295.49464a47.2107,47.2107,0,0,0-47.21079,47.2106V634.21209a47.2107,47.2107,0,0,0,47.21065,47.21075H468.31235a47.21071,47.21071,0,0,0,47.21084-47.21057v-307.868h2.97764Z"
				transform="translate(-191.89234 -139.5)"
				fill="#3f3d56"
			/>
			<path
				d="M470.21737,151.7826H447.65892A16.75027,16.75027,0,0,1,432.15047,174.859H333.14532a16.75024,16.75024,0,0,1-15.50845-23.0764H296.56722A35.25642,35.25642,0,0,0,261.31077,187.039V633.88382a35.25643,35.25643,0,0,0,35.25641,35.25646H470.21737a35.25643,35.25643,0,0,0,35.25645-35.25642h0V187.039A35.2564,35.2564,0,0,0,470.21737,151.7826Z"
				transform="translate(-191.89234 -139.5)"
				fill="#fff"
			/>
			<path
				d="M573.89234,681.5h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
				transform="translate(-191.89234 -139.5)"
				fill="#cbcbcb"
			/>
			<circle
				id="baf12095-0797-4180-a98d-6422936d747a"
				data-name="Ellipse 44"
				cx="191.5"
				cy="261.2336"
				r="84.4462"
				fill="#6c63ff"
			/>
			<path
				id="b582f34e-fd02-4670-b1d8-d23f069ed737"
				data-name="Path 395"
				d="M375.02417,432.94733a8.75832,8.75832,0,0,1-5.26905-1.75113l-.09426-.07069-19.846-15.18155a8.81941,8.81941,0,1,1,10.73182-13.99843l12.85463,9.85753L403.77751,372.174a8.81571,8.81571,0,0,1,12.36-1.63259l.00256.00192-.18852.26177.19363-.26177a8.82633,8.82633,0,0,1,1.63067,12.36249l-35.729,46.5917a8.821,8.821,0,0,1-7.01492,3.4394Z"
				transform="translate(-191.89234 -139.5)"
				fill="#fff"
			/>
			<path
				d="M886.13718,689.8a26.26334,26.26334,0,0,1,3.60591-4.59509c.56451-.58554,1.16006-1.13726,1.774-1.674a28.1419,28.1419,0,0,1,46.402,16.00717c4.1243-15.25223-6.61178-30.27563-17.40582-41.81557-10.79975-11.536-23.14326-24.19113-23.08122-39.99834.03021-8.79567,4.33288-16.64261,10.82915-22.46017.20041-.17628.40083-.35231.60533-.52222a41.0711,41.0711,0,0,1,28.288-9.85832c19.199,1.02837,35.96271,13.93191,47.60455,29.23771,18.73895,24.62722,26.9504,59.1966,13.83175,87.23109-11.539,24.65645-36.14884,39.298-61.26527,51.06882q-5.26069,2.466-10.50056,4.78336c-.02286.006-.04816.02255-.07143.02873-.16817.07378-.33634.14776-.4939.22384-.72086.318-1.44212.63631-2.15685.95035l.331.21649,1.0327.66861c-.358-.21685-.71228-.42716-1.07026-.644-.10653-.06567-.21919-.12741-.32532-.19333-12.33575-7.40167-24.84335-15.23213-33.36543-26.78769C881.86716,719.66952,878.3135,702.48392,886.13718,689.8Z"
				transform="translate(-191.89234 -139.5)"
				fill="#f1f1f1"
			/>
			<path
				d="M968.44,661.93639a69.81787,69.81787,0,0,1,5.37575,19.52985,60.62388,60.62388,0,0,1-.73111,18.01244,77.67473,77.67473,0,0,1-15.57738,33.3225,111.79167,111.79167,0,0,1-20.18167,19.61994q-5.26048,2.466-10.50056,4.78335c-.02295.006-.04825.02253-.07151.02871-.168.0738-.3363.14776-.494.22384-.72086.318-1.442.63631-2.15666.95034l.331.2165,1.03268.6686c-.35805-.21683-.71227-.42715-1.07032-.644-.10638-.06569-.219-.12739-.32505-.19332a61.79626,61.79626,0,0,0-11.20738-55.64651A62.32549,62.32549,0,0,0,889.74327,685.205c.5644-.58556,1.15976-1.13725,1.77368-1.674a64.61916,64.61916,0,0,1,9.90358,5.655,63.31719,63.31719,0,0,1,23.95863,31.73973,64.7349,64.7349,0,0,1,1.40482,36.18842c.61913-.40489,1.24061-.82034,1.845-1.23364,11.492-7.7498,22.08315-17.05708,30.06094-28.47079a72.261,72.261,0,0,0,12.82157-32.58459c1.529-12.83375-1.68219-25.454-7.41183-36.90528a151.25676,151.25676,0,0,0-23.69628-33.67008,171.87225,171.87225,0,0,0-31.77612-27.322,1.23572,1.23572,0,0,1-.36615-1.6637,1.05106,1.05106,0,0,1,.6054-.52219.915.915,0,0,1,.77957.15764c1.44031.96125,2.86827,1.93058,4.2789,2.92929a173.8225,173.8225,0,0,1,31.76516,29.01581C954.72121,637.39963,962.94,649.12629,968.44,661.93639Z"
				transform="translate(-191.89234 -139.5)"
				fill="#fff"
			/>
			<circle
				id="ae2e9f9a-ed63-4994-961d-af4fbb6f5199"
				data-name="Ellipse 44"
				cx="525.04172"
				cy="21.27646"
				r="17.93406"
				fill="#f1f1f1"
			/>
			<circle
				id="b777be60-18da-4893-88a8-55a275d98bc2"
				data-name="Ellipse 44"
				cx="748.04172"
				cy="268.27646"
				r="10.93406"
				fill="#f1f1f1"
			/>
			<circle
				id="a703acd2-aa23-4bff-9a86-75b7d9a928c1"
				data-name="Ellipse 44"
				cx="428.04172"
				cy="298.27646"
				r="10.93406"
				fill="#f1f1f1"
			/>
			<path
				d="M626.10766,760.5h381a1,1,0,1,0,0-2h-381a1,1,0,0,0,0,2Z"
				transform="translate(-191.89234 -139.5)"
				fill="#cbcbcb"
			/>
			<path
				d="M965.062,236.135a14.65213,14.65213,0,0,0-28.13652-7.42622l-30.91721,35.44433,9.07157,25.33678,31.093-41.4153A14.573,14.573,0,0,0,965.062,236.135Z"
				transform="translate(-191.89234 -139.5)"
				fill="#ffb7b7"
			/>
			<polygon
				points="645.013 234.549 747.642 125.02 733.799 103.012 605.076 211.876 645.013 234.549"
				fill="#3f3d56"
			/>
			<circle cx="622.10766" cy="132" r="36" fill="#2f2e41" />
			<path
				d="M816.8938,251.16321A75.48534,75.48534,0,0,1,844.35693,233.404c6.63868-2.45941,13.86451-3.97894,20.80494-2.58221,6.94067,1.39666,13.50415,6.19806,15.44042,13.00775,1.58326,5.56836-.05151,11.56378-2.50878,16.80554-2.457,5.24176-5.73755,10.10248-7.72461,15.53986a35.468,35.468,0,0,0,35.68921,47.56231c-6.81934.91437-13.10547,4.119-19.771,5.82483-6.66553,1.70575-14.53272,1.59009-19.48608-3.18524-5.241-5.05243-5.346-13.26715-5.09253-20.54248q.56507-16.22241,1.13012-32.44495c.19214-5.51544.3562-11.20764-1.63305-16.35553-1.989-5.14788-6.71607-9.6557-12.23462-9.60882-4.18262.03546-7.88452,2.56927-11.23853,5.068-3.35449,2.49878-6.90454,5.16474-11.0708,5.53638-4.166.3717-8.92285-2.71143-8.61108-6.88245Z"
				transform="translate(-191.89234 -139.5)"
				fill="#2f2e41"
			/>
			<path
				d="M677.26948,513.22286a14.65213,14.65213,0,0,1-11.0391-26.92491l31.10052-35.28358,26.30491,5.68307-36.99733,36.23807a14.573,14.573,0,0,1-9.36894,20.28732Z"
				transform="translate(-191.89234 -139.5)"
				fill="#ffb7b7"
			/>
			<polygon
				points="587.698 247.405 510.183 344.705 486.555 333.857 577.665 192.012 587.698 247.405"
				fill="#3f3d56"
			/>
			<polygon
				points="525.848 541.211 507.374 540.921 509.596 603.887 525.845 603.887 525.848 541.211"
				fill="#ffb7b7"
			/>
			<path
				d="M721.8823,738.08194l-32.00113-.00128h-.00131A20.39476,20.39476,0,0,0,669.48624,758.474v.66272l52.39508.00195Z"
				transform="translate(-191.89234 -139.5)"
				fill="#2f2e41"
			/>
			<polygon
				points="648.165 524.511 627.984 537.467 668.352 586.032 682.026 577.253 648.165 524.511"
				fill="#ffb7b7"
			/>
			<path
				d="M874.53983,710.04911,847.61075,727.3378l-.00109.00067a20.39471,20.39471,0,0,0-6.14264,28.179l.358.55768,44.09051-28.3067Z"
				transform="translate(-191.89234 -139.5)"
				fill="#2f2e41"
			/>
			<path
				d="M847.24693,471.74009s-44.83957-148.9274-59.24978-150.93993-37.62808,38.21305-37.62808,38.21305c-30.103,38.40821-61.144,162.82087-61.144,162.82087s51.74659,3.23809,89.57339,23.36344,82.46764-1.0188,82.46764-1.0188Z"
				transform="translate(-191.89234 -139.5)"
				fill="#3f3d56"
			/>
			<path
				d="M847.24693,471.74009s-44.83957-148.9274-59.24978-150.93993-31.73106,38.62126-31.73106,38.62126c-15,62-67.041,162.41266-67.041,162.41266s51.74659,3.23809,89.57339,23.36344,82.46764-1.0188,82.46764-1.0188Z"
				transform="translate(-191.89234 -139.5)"
				opacity="0.1"
				style={{ isolation: 'isolate' }}
			/>
			<polygon
				points="618.567 287.682 618.567 362.517 627.374 470.921 671.374 552.921 650.374 567.921 590.374 489.921 566.643 412.472 529.374 583.921 501.374 581.921 520.82 344.21 557.721 277.3 618.567 287.682"
				fill="#2f2e41"
			/>
			<path
				d="M765.24985,354.79968c-14,14-1,29-1,29l-16,36,63,17,22.01624-98.37826-15-16c-30-1-30.01624-1.62174-30.01624-1.62174C782.99363,325.94351,765.24985,354.79968,765.24985,354.79968Z"
				transform="translate(-191.89234 -139.5)"
				fill="#6c63ff"
			/>
			<path
				d="M835.8032,328.22953a26.8839,26.8839,0,0,0-37.77734,5.91308c-15.83789,22.05225-30.48242,57.66114-10.30469,102.07178,32.39893,71.30957,60.59326,107.81445,72.05518,107.81055a4.55981,4.55981,0,0,0,1.47314-.23145c7.05518-2.39355,17.25586-14.332,19.10254-24.85254.66113-3.76562.50391-9.01074-3.99951-12.6123-12.93555-10.34619-36.15772-42.56152-43.40186-84.96387-4.00341-23.42969,3.76026-44.772,10.978-58.5498a26.87922,26.87922,0,0,0-7.77734-34.3291h0Z"
				transform="translate(-191.89234 -139.5)"
				fill="#3f3d56"
			/>
			<circle
				cx="803.14042"
				cy="280.71068"
				r="26.50012"
				transform="translate(-228.11672 280.13426) rotate(-28.6632)"
				fill="#ffb7b7"
			/>
			<path
				d="M765.872,278.70709h38.26556l.39252-5.49379,1.96191,5.49379h5.89209l.77759-10.88853,3.88855,10.88853h11.40088v-.53947a28.62427,28.62427,0,0,0-28.59192-28.59215h-5.395A28.62448,28.62448,0,0,0,765.872,278.16762Z"
				transform="translate(-191.89234 -139.5)"
				fill="#2f2e41"
			/>
			<path
				d="M822.67028,298.45432,839,293.1556V253.5H808.15564l.76361.76243C819.54388,264.87334,831.36033,302.51949,822.67028,298.45432Z"
				transform="translate(-191.89234 -139.5)"
				fill="#2f2e41"
			/>
		</svg>
	);
};

export default OrderPlaced;
