import { Button, Modal, Select } from 'antd';

import CanvasActions from '../../redux/CanvasRedux';
import { reportError } from '../../services/Reporting';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';

const { Option } = Select;

const DataSource = () => {
	const [dataSource, setDataSource] = useState<string>();
	const [orderId, setOrderId] = useState<string>();
	const [userIds, setUserIds] = useState<string[]>([]);
	const [userId, setUserId] = useState<string>();
	const [orderIds, setOrderIds] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const getDataFromSource = async (dataSource: string, params?: any) => {
		setLoading(true);
		try {
			const url = new URL(`${process.env.REACT_APP_URL}/api/${dataSource}`);

			if (params) {
				Object.keys(params).forEach((key) =>
					// @ts-ignore
					url.searchParams.append(key, params[key])
				);
			}

			// @ts-ignore
			const response = await fetch(url);

			const data = await response.json();

			return data;
		} catch (error) {
			reportError(error);

			Modal.error({
				title: 'Oops! something went wrong',
				content: 'Please refresh to try again!',
				onOk: () => {},
			});
		} finally {
			setLoading(false);
		}
	};

	const fetchUserIds = async (value: string) => {
		const userIds = await getDataFromSource(value);

		setDataSource(value);
		setUserIds(userIds);
	};

	const fetchOrderIdsForUser = async (value: string) => {
		setUserId(value);
		const orderIds = await getDataFromSource('orders', { userId: value });

		setOrderIds(orderIds);
	};

	const fetchOrderData = async (value: string) => {
		const orderData = await getDataFromSource('orderData', {
			userId,
			orderId: value,
		});

		const { canvasData } = orderData;

		dispatch(
			CanvasActions.adminSaveCanvasState({
				...canvasData,
			})
		);

		setOrderId(value);
	};

	return (
		<>
			<Select
				onChange={fetchUserIds}
				placeholder="Select source"
				style={{ width: '80%', marginBottom: 10 }}
			>
				<Option value="orders">Orders</Option>
			</Select>

			<Select
				onChange={fetchOrderIdsForUser}
				placeholder="Select user"
				style={{ width: '80%', marginBottom: 10 }}
				loading={loading}
				disabled={!dataSource}
				showSearch
			>
				{userIds.map((orderId) => (
					<Option value={orderId} key={orderId}>
						{orderId}
					</Option>
				))}
			</Select>

			<Select
				onChange={fetchOrderData}
				placeholder="Select order id"
				style={{ width: '80%', marginBottom: 10 }}
				loading={loading}
				disabled={!userId}
				showSearch
			>
				{orderIds.map((orderId) => (
					<Option value={orderId} key={orderId}>
						{orderId}
					</Option>
				))}
			</Select>
			{dataSource && userId && orderId && (
				<>
					<br />
					<Button onClick={() => history.go(0)}>Load</Button>
				</>
			)}
		</>
	);
};

export default DataSource;
