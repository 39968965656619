import { CirclePicker, ColorResult } from 'react-color';

import CanvasActions from '../../redux/CanvasRedux';
import { Typography } from 'antd';
import { tShirtColors } from '../../data';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

const TShirtColorPicker = () => {
	const dispatch = useDispatch();

	const updateTShirtColor = (color: ColorResult): void => {
		const { hex } = color;

		dispatch(
			CanvasActions.saveCanvasState({
				tShirtColor: hex,
			})
		);
	};

	return (
		<>
			<Title level={4}>T-Shirt Color</Title>
			<CirclePicker
				onChangeComplete={updateTShirtColor}
				colors={tShirtColors}
				circleSize={35}
			/>
		</>
	);
};

export default TShirtColorPicker;
