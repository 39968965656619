import { Button, Col, Row } from 'antd';
import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { redo, undo } from '../../utils/fabricCanvasHelper';

interface ICanvasHistory {
	canvas: fabric.Canvas;
}

const HistoryControls = (props: ICanvasHistory) => {
	const { canvas } = props;

	const undoAction = () => {
		undo(canvas);
	};

	const redoAction = () => {
		redo(canvas);
	};

	return (
		<div id="tour-step8" style={{ margin: '10px 0px' }}>
			<Row gutter={[10, 10]}>
				<Col flex={1}>
					<Button
						shape="round"
						size={'large'}
						icon={<UndoOutlined style={{ fontSize: '26px', color: '#08c' }} />}
						onClick={undoAction}
						block
					>
						Undo
					</Button>
				</Col>
				<Col flex={1}>
					<Button
						shape="round"
						size={'large'}
						icon={<RedoOutlined style={{ fontSize: '26px', color: '#08c' }} />}
						onClick={redoAction}
						block
					>
						Redo
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default HistoryControls;
