import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { TermsOfUse as TermsOfUseContent } from '../components/TermsOfUse';

const { Content } = Layout;

export const TermsOfUse = () => {
	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Terms of Use</title>
			</Helmet>
			<TermsOfUseContent />
		</Content>
	);
};

export default TermsOfUse;
