import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

export const reducers = (history: History<unknown>) =>
	combineReducers({
		router: connectRouter(history),
		auth: require('./AuthRedux').reducer,
		cart: require('./CartRedux').reducer,
		canvas: require('./CanvasRedux').reducer,
		loading: require('./LoadingRedux').reducer,
	});
