import { Col, Row, Spin } from 'antd';

import BannerTShirtCanvas from './Canvas/BannerTShirtCanvas';
import { LoadingOutlined } from '@ant-design/icons';
import { useDesign } from '../hooks/useDesign';

interface ITShirtDesign {
	canvasId: string;
}

export const TShirtDesignViewer = (props: ITShirtDesign) => {
	const { canvasId } = props;
	const { canvasData, loading } = useDesign(canvasId);
	const {
		frontCanvasData,
		backCanvasData,
		shirtTitle,
		tShirtColor,
		printBackSide,
	} = canvasData || {
		frontCancasData: '',
		backCanvasData: '',
		shirtTitle: '',
		tShirtColor: '',
	};

	return (
		<>
			{loading ? (
				<Spin
					size={'large'}
					style={{ margin: '20% 50%' }}
					indicator={<LoadingOutlined spin />}
				/>
			) : (
				<Row>
					<Col span={12}>
						<BannerTShirtCanvas
							data={frontCanvasData}
							id={`${shirtTitle}-front`}
							backgroundImage={
								require('../assets/images/tshirt_front.webp').default
							}
							selectedColor={tShirtColor}
							selectedSize={11}
						/>
					</Col>
					{printBackSide && backCanvasData && (
						<Col span={12}>
							<BannerTShirtCanvas
								data={backCanvasData}
								id={`${shirtTitle}-back`}
								backgroundImage={
									require('../assets/images/tshirt_back.webp').default
								}
								selectedColor={tShirtColor}
								selectedSize={11}
							/>
						</Col>
					)}
				</Row>
			)}
		</>
	);
};

export default TShirtDesignViewer;
