import { Button, Result, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CartActions from '../redux/CartRedux';
import { Content } from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';
import { IState } from '../interface';
import Icon from '@ant-design/icons';
import OrderPlaced from '../components/Icons/OrderPlaced';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const OrderPlacedIcon = () => <Icon component={OrderPlaced} />;

export const CheckoutSuccess = () => {
	const history = useHistory();
	const cart = useSelector((state: IState) => state.cart);
	const { items } = cart;
	const dispatch = useDispatch();

	useEffect(() => {
		if (items.length) {
			dispatch(CartActions.resetCart());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const navigateToPath = (pathname: string) => {
		history.push({ pathname });
	};

	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Checkout Success</title>
			</Helmet>
			<Result
				status="success"
				title="Order placed successfully"
				subTitle="You will receive an email confirmation shortly"
				icon={<OrderPlacedIcon />}
				extra={[
					<Space key="container">
						<Button onClick={() => navigateToPath('/')} key="home">
							Go Home
						</Button>
						<Button
							type="primary"
							onClick={() => navigateToPath('design')}
							key="design"
						>
							Design another
						</Button>
						<Button
							type="primary"
							onClick={() => navigateToPath('orders')}
							key="orders"
						>
							View order
						</Button>
					</Space>,
				]}
			/>
		</Content>
	);
};

export default CheckoutSuccess;
