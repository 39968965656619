import './Hero.css'

import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

export const Hero = () => {
	return (
		<section>
			<div
				className="bg-hero mt-16 pt-12 lg:pt-20 pb-28"
			>
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap -mx-4">
						<div className="w-full lg:w-1/2 px-4 mb-12 md:mb-20 lg:mb-0 flex items-center">
							<div className="w-full text-center lg:text-left">
								<div className="max-w-md mx-auto lg:mx-0">
									<h2 className="mb-3 text-4xl lg:text-5xl text-white font-bold">
										<span>Custom Printing</span>
									</h2>
									<h2 className="mb-3 text-4xl lg:text-5xl text-white font-bold">
										<span className="text-yellow-400">
											One-stop shop for custom design t-shirts
										</span>
									</h2>
								</div>
								<div className="max-w-sm mx-auto lg:mx-0">
									<p className="mb-6 text-white leading-loose">
										Easily create your custom designs in minutes
									</p>
									<div>
										<Link
											to="/design"
											className="inline-block w-full lg:w-auto py-2 px-6 leading-loose text-white font-semibold bg-pink-600 border-2 border-pink-400 hover:border-pink-500 hover:text-white rounded-l-xl rounded-t-xl transition duration-200"
										>
											Get Started
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full lg:w-1/2 px-4 flex items-center justify-center">
							<div className="relative" style={{ zIndex: 0 }}>
								<ScrollAnimation animateOnce={true} animateIn="pulse">
									<img
										className="h-128 w-full max-w-lg object-cover rounded-3xl md:rounded-br-none"
										src={require('../../assets/images/hero.webp').default}
										alt=""
									/>
									{/* <img
										className="md:block absolute hidden"
										style={{ top: '-6rem', left: '-14rem', zIndex: -1 }}
										src={require('../../assets/images/blob1.svg').default}
										alt=""
									/>
									<img
										className="md:block absolute hidden"
										style={{ bottom: '-2rem', right: '-2rem', zIndex: -1 }}
										src={require('../../assets/images/blob2.svg').default}
										alt=""
									/>
									<img
										className="md:block absolute hidden"
										style={{ top: '3rem', right: '-3rem', zIndex: -1 }}
										src={
											require('../../assets/images/bullets-yellow-right.svg')
												.default
										}
										alt=""
									/>
									<img
										className="md:block absolute hidden"
										style={{ bottom: '2.5rem', left: '-4.5rem', zIndex: -1 }}
										src={
											require('../../assets/images/bullets-yellow-left.svg')
												.default
										}
										alt=""
									/> */}
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
