const description = {
	title: '',
	dataIndex: 'description',
	key: 'description',
	value: 'description',
	label: '',
};

const t2 = {
	title: '2T',
	dataIndex: 't2',
	key: 't2',
	value: ['2T', '7'],
	label: '2T',
};

const t3 = {
	title: '3T',
	dataIndex: 't3',
	key: 't3',
	value: ['3T', '8'],
	label: '3T',
};

const t4 = {
	title: '4T',
	dataIndex: 't4',
	key: 't4',
	value: ['4T', '9'],
	label: '4T',
};

const xs = {
	title: 'XS',
	dataIndex: 'xs',
	key: 'xs',
	value: ['XS', '9'],
	label: 'XS',
};

const small = {
	title: 'S',
	dataIndex: 'small',
	key: 'small',
	value: ['S', '10'],
	label: 'Small',
};

const youthMedium = {
	title: 'M',
	dataIndex: 'medium',
	key: 'medium',
	value: ['M', '10.5'],
	label: 'Medium',
};

const medium = {
	title: 'M',
	dataIndex: 'medium',
	key: 'medium',
	value: ['M', '11'],
	label: 'Medium',
};

const youthLarge = {
	title: 'L',
	dataIndex: 'large',
	key: 'large',
	value: ['L', '12'],
	label: 'Large',
};

const large = {
	title: 'L',
	dataIndex: 'large',
	key: 'large',
	value: ['L', '12'],
	label: 'Large',
};

const youthXLarge = {
	title: 'XL',
	dataIndex: 'xl',
	key: 'xl',
	value: ['XL', '12'],
	label: 'XL',
};

const xLarge = {
	title: 'XL',
	dataIndex: 'xl',
	key: 'xl',
	value: ['XL', '12'],
	label: 'XL',
};

const xLarge2 = {
	title: '2XL',
	dataIndex: '2xl',
	key: '2xl',
	value: ['2XL', '12.5'],
	label: '2XL',
};

const xLarge3 = {
	title: '3XL',
	dataIndex: '3xl',
	key: '3xl',
	value: ['3XL', '12.5'],
	label: '3XL',
};

const xLarge4 = {
	title: '4XL',
	dataIndex: '4xl',
	key: '4xl',
	value: ['4XL', '12.5'],
	label: '4XL',
};

export const coreTeeColumns = [
	description,
	small,
	medium,
	large,
	xLarge,
	xLarge2,
	xLarge3,
	xLarge4,
];

export const youthCoreTeeColumns = [
	description,
	xs,
	small,
	youthMedium,
	youthLarge,
	youthXLarge,
];

export const toddlerCoreTeeColumns = [description, t2, t3, t4];

export const tshirtSizeData = [
	{
		type: 'Core Cotton Tee',
		columns: coreTeeColumns,
		data: [
			{
				description: 'Chest Width',
				small: 18,
				medium: 20,
				large: 22,
				xl: 24,
				'2xl': 26,
				'3xl': 28,
				'4xl': 30,
			},
			{
				description: 'Sleeve Length from Center Back',
				small: '16 3/4',
				medium: '17 3/4',
				large: '18 3/4',
				xl: '19 3/4',
				'2xl': '20 3/4',
				'3xl': '21 3/4',
				'4xl': '22 3/4',
			},
			{
				description: 'Body Length at Back',
				small: 28,
				medium: 29,
				large: 30,
				xl: 31,
				'2xl': 32,
				'3xl': 33,
				'4xl': 34,
			},
		],
	},
	{
		type: 'Youth Core Cotton Tee',
		columns: youthCoreTeeColumns,
		data: [
			{
				description: 'Chest Width',
				xs: 14,
				small: 16,
				medium: 17,
				large: 18,
				xl: 19,
			},
			{
				description: 'Sleeve Length from Center Back',
				xs: '11 3/4',
				small: '13 3/4',
				medium: '14 3/4',
				large: '15 3/4',
				xl: '16 3/4',
			},
			{
				description: 'Body Length at Back',
				xs: 19,
				small: 21,
				medium: 23,
				large: 25,
				xl: 27,
			},
		],
	},
	{
		type: 'Toddler Core Cotton Tee',
		columns: toddlerCoreTeeColumns,
		data: [
			{
				description: 'Chest Width',
				t2: '12 1/4',
				t3: '12 3/4',
				t4: '13 1/4',
			},
			{
				description: 'Sleeve from Shoulder',
				t2: '9 3/4',
				t3: '10 1/4',
				t4: '10 3/4',
			},
			{
				description: 'Body Length at Back',
				t2: 16,
				t3: 17,
				t4: 18,
			},
		],
	},
];

export const tshirtSizeOptions = [
	{
		value: 'Adult',
		label: 'Adult',
		children: coreTeeColumns,
	},
	{
		value: 'Youth',
		label: 'Youth',
		children: youthCoreTeeColumns,
	},
	{
		value: 'Toddler',
		label: 'Toddler',
		children: toddlerCoreTeeColumns,
	},
];

export const featuredItems = [
	{
		title: 'Two friends',
		description: 'put their nickname in #Gold and #Silver',
		image:
			'https://scontent-hkg4-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/70706046_158835821888349_852151456420466648_n.jpg?_nc_ht=scontent-hkg4-2.cdninstagram.com&_nc_cat=109&_nc_ohc=rmk4n2bZBdIAX_GN1hb&tp=24&oh=04b36b79685c427d15f6a1115fbc9bd4&oe=5FDD1571',
	},
	{
		title: 'He said',
		description: 'it’s his best birthday gift',
		image:
			'https://scontent-hkg4-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/p750x750/42787148_287924991833711_4450870503759985600_n.jpg?_nc_ht=scontent-hkg4-2.cdninstagram.com&_nc_cat=111&_nc_ohc=q3Cj_gR5j9cAX_3QGpw&tp=24&oh=7cd81059fff06c1ab68313c2f92092c9&oe=5FDE4ADF',
	},
	{
		title: 'Rest in peace X',
		description: 'Let his energy live within us.',
		image:
			'https://scontent-hkg4-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/43405541_309147056340201_2261160722253107588_n.jpg?_nc_ht=scontent-hkg4-2.cdninstagram.com&_nc_cat=109&_nc_ohc=pDpCqndFCZcAX-8MLJY&tp=24&oh=8895dbd3f427cc72c5466da7081daf4b&oe=5FDF98B3',
	},
	{
		title: 'Show your pride',
		description: 'with custom T-shirts',
		image:
			'https://scontent-hkg4-2.cdninstagram.com/v/t51.2885-15/e35/75318254_454265725205039_3725307752638829538_n.jpg?_nc_ht=scontent-hkg4-2.cdninstagram.com&_nc_cat=111&_nc_ohc=149pqyrfRUAAX-CaYvq&tp=18&oh=a32692b6a4462e5a1f8db48b281a0e77&oe=5FDFD9C4',
	},
];

export const fonts = [
	'Alaska Script',
	'Batman Forever',
	'Bones',
	'Brannboll',
	'Broadway',
	'Comic Sans',
	'Cowboys',
	'Dodger',
	'Double Feature',
	'Feast Of Flesh',
	'Fiolex Girls',
	'Firestrater', // exact font not found, used similar one
	'Franklin',
	'Georgia',
	'Harry P',
	'Impact',
	'Loki Cola',
	'Old English',
	'Patriot',
	'Potland',
	'Pr Creepy',
	// 'Quigley Wiggly', font not working
	'Star Jedi',
	'Super Mario',
	'Tattoo Ink',
	'Team Fury',
	'Team Spirit',
	'United States',
	'Waltograph',
	'Watermelon',
];

export const textAlignments = ['left', 'center', 'right'];

export const tShirtColors = [
	'#000000',
	'#ff0000',
	'#ffffff',
	'#f81893',
	'#008000',
	'#c0c0c0',
	'#fff34f',
	'#37ff14',
	'#87cfeb',
	'#e1ff00',
	'#9bddff',
	'#c5b358',
	'#0000ff',
	'#65367e',
	'#ff6700'
];
