import './OurDesigns.css';

import { Link } from 'react-router-dom';
import TShirtDesignList from '../TShirtDesignList';

interface IOurDesigns {
	limit: number;
}

export const OurDesigns = (props: IOurDesigns) => {
	const { limit } = props;

	return (
		<section>
			<div className="bg-designs py-20 radius-for-skewed">
				<div className="container mx-auto">
					<div className="max-w-xl mx-auto mb-10 text-center">
						<h2 className="text-white text-4xl lg:text-5xl font-bold font-heading">
							Check out some of our exclusive designs
						</h2>
					</div>
					<div className="p-5 mb-5 flex flex-wrap">
						<TShirtDesignList limit={limit} />
					</div>
					<div className="max-w-xl mx-auto mb-10 text-center">
						<Link
							className="inline-block w-full lg:w-auto py-2 px-6 leading-loose text-white font-semibold bg-purple-600 border-2 border-purple-400 hover:border-purple-500 hover:text-white rounded-l-xl rounded-t-xl transition duration-200"
							to="/designs"
						>
							View all designs
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurDesigns;
