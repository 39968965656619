import { Button, Col, Modal, Row, Tag } from 'antd';
import { ICanvasData, IState } from '../../interface';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import CartActions from '../../redux/CartRedux';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { SigninForm } from '../Login/SigninForm';
import TShirtDesignViewer from '../TShirtDesignViewer';
import { firebaseAuth } from '../../config/firebase';
import { getPrice } from '../../utils/price';

interface ICartControls {
	canvas: fabric.Canvas;
}

export default function CartControls(props: ICartControls) {
	const { canvas } = props;
	const state = useSelector((state: IState) => state);
	const { canvas: canvasState, cart: cartState, loading: loadingState } = state;

	const { items: cartItems } = cartState;

	const dispatch = useDispatch();
	const [loginModalVisible, setLoginModalVisible] = useState<boolean>(false);
	const [viewDesignModalVisible, setViewDesignModalVisible] = useState<boolean>(
		false
	);

	const { id, printBackSide, tShirtColor, tShirtSize } = canvasState;
	const { cartLoading } = loadingState;

	const addToCart = () => {
		if (!firebaseAuth().currentUser) {
			setLoginModalVisible(true);

			// history.push({ pathname: '/checkout' });
			// history.push('/checkout');

			return;
		}

		const canvasHasData = checkIfCanvasHasData();

		if (!canvasHasData) {
			Modal.warn({
				title: 'Oops! Canvas is empty',
				content: 'Please create a design before adding to cart!',
				onOk: () => {},
			});

			return;
		}

		setViewDesignModalVisible(true);
	};

	const captureCanvasImage = () => {
		canvas.discardActiveObject().renderAll();
		dispatch(CanvasActions.uploadDesignToStorage({ canvas, canvasId: id }));
	};

	const checkIfCanvasHasData = () => {
		let frontCanvasHasData = false;
		let backCanvasHasData = false;

		const { frontCanvasData, backCanvasData } = canvasState;

		if (!frontCanvasData && !backCanvasData) {
			return false;
		}

		if (frontCanvasData) {
			const data: ICanvasData = JSON.parse(frontCanvasData);
			const { objects } = data;
			if (objects.length) {
				frontCanvasHasData = true;
			}
		}

		if (backCanvasData) {
			const data: ICanvasData = JSON.parse(backCanvasData);
			const { objects } = data;
			if (objects.length) {
				backCanvasHasData = true;
			}
		}

		return frontCanvasHasData || backCanvasHasData;
	};

	const onLoginModalCancel = () => {
		setLoginModalVisible(false);
	};

	const onDesignModalCancel = () => {
		setViewDesignModalVisible(false);
	};

	const onDesignModalOk = () => {
		setViewDesignModalVisible(false);

		const price = getPrice(printBackSide);
		const size = `${tShirtSize[0]}-${tShirtSize[1][0]}`;
		const cartData = {
			id,
			printBackSide,
			size,
			name: id,
			quantity: 1,
			price: price,
			color: tShirtColor,
		};

		if (cartItems.find((c) => c.id === id)) {
			dispatch(CartActions.updateCartItem(cartData));
		} else {
			dispatch(CartActions.addCartItem(cartData));
		}

		captureCanvasImage();
	};

	const onUserLogin = () => {
		setLoginModalVisible(false);

		setTimeout(() => {
			addToCart();
		}, 3000);
	};

	return (
		<>
			<Row>
				<Col flex={1}>
					<Button
						shape="round"
						size={'large'}
						block
						icon={<ShoppingCartOutlined style={{ fontSize: '26px' }} />}
						onClick={addToCart}
						loading={cartLoading}
					>
						Add to cart
					</Button>
				</Col>
			</Row>
			<Modal
				title="Sign in"
				visible={loginModalVisible}
				onCancel={onLoginModalCancel}
				width={'80%'}
				footer={null}
				centered
			>
				<SigninForm onLogin={onUserLogin} />
			</Modal>
			<Modal
				title="Please check the design!"
				visible={viewDesignModalVisible}
				onOk={onDesignModalOk}
				onCancel={onDesignModalCancel}
				width={1000}
				okText="Yes"
				cancelText="No"
				centered
				destroyOnClose
			>
				<>
					<Tag color="green" style={{ fontSize: 14 }}>
						If the design is not up to date, please update your changes or
						refresh the page and try again.
					</Tag>
					<TShirtDesignViewer canvasId={id} />
				</>
			</Modal>
		</>
	);
}
