import { firebaseAuth, firestore } from '../config/firebase';
import { useEffect, useState } from 'react';

import { ITShirtDesign } from '../interface';
import { message } from 'antd';
import { reportError } from '../services/Reporting';

export const useOrder = () => {
	const [orders, setOrders] = useState<ITShirtDesign[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const user = firebaseAuth().currentUser;

	useEffect(() => {
		async function getOrders() {
			try {
				const orders = await firestore()
					.doc(`order/${user?.uid}`)
					.collection('data')
					.get();

				const ordersData = orders.docs.map((doc) => {
					return { id: doc.id, data: doc.data() };
				});

				setOrders(ordersData);
				setLoading(false);
			} catch (error) {
				setOrders([]);
				setLoading(false);

				message.error('Something went wrong, please try again!');

				reportError(error);
			}
		}

		getOrders();
	}, [user?.uid]);

	return { orders, loading };
};
