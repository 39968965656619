import { useEffect, useState } from 'react';

import { ITShirtDesign } from '../interface';
import { firestore } from '../config/firebase';
import { message } from 'antd';
import { reportError } from '../services/Reporting';

export const useDesigns = (limit: number) => {
	const [designs, setDesigns] = useState<ITShirtDesign[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		let isCanceled = false;

		const getData = async () => {
			if (!isCanceled) {
				try {
					const snapshot = await firestore()
						.collection('designs')
						.limit(limit)
						.get();

					const designs = snapshot.docs.map((doc) => {
						return { id: doc.id, data: doc.data() };
					});

					setDesigns(designs);
					setLoading(false);
					setError(false);
				} catch (error) {
					setDesigns([]);
					setLoading(false);
					setError(true);

					reportError(error);

					message.error('Something went wrong!');
				}
			}
		};

		getData();

		return () => {
			isCanceled = true;
		};
	}, [limit]);

	return { designs, loading, error };
};
