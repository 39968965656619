import { Upload, message } from 'antd';

import LoadingActions from '../../redux/LoadingRedux';
import { PlusOutlined } from '@ant-design/icons';
import { fabric } from 'fabric';
import { useDispatch } from 'react-redux';

interface IImageUpload {
	canvas: fabric.Canvas;
}

export const ImageUpload = (props: IImageUpload) => {
	const { canvas } = props;
	const dispatch = useDispatch();

	const onImageChangeHandler = (event: any) => {
		const file = event.file.originFileObj;

		const reader = new FileReader();
		const status = event.file.status;
		reader.onload = (e: any) => {
			const image = e.target.result;
			if (status !== 'uploading') {
				if (file.size / 1024 > 2000) {
					message.error(
						'Image size too big, please upload files less than 2 MB'
					);
					return;
				}
				addImageToCanvas(image);
			}
		};
		reader.readAsDataURL(file);
	};

	const addImageToCanvas = (image: any) => {
		if (image) {
			dispatch(LoadingActions.setCanvasLoading(true));

			fabric.Image.fromURL(image, (img) => {
				var canvasImage = img
					.set({
						left: 0,
						top: 50,
					})
					.scale(0.2);
				canvas.centerObject(canvasImage);
				// @ts-ignore
				canvas.add(canvasImage).setActiveObject(canvasImage);
				canvas.renderAll();

				dispatch(LoadingActions.setCanvasLoading(false));
			});
		}
	};

	return (
		<Upload
			listType="picture-card"
			id="img"
			name="img"
			accept="image/png,image/svg+xml"
			onChange={onImageChangeHandler}
		>
			<div>
				<PlusOutlined />
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		</Upload>
	);
};

export default ImageUpload;
