import { Button, Form, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import GoogleLogin from './GoogleLogin';
import { IState } from '../../interface';
import { firebaseAuth } from '../../config/firebase';
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface ISigninForm {
	onLogin?: () => void;
}

export const SigninForm = (props: ISigninForm) => {
	const { onLogin } = props;
	const user = useAuth(onLogin);
	const history = useHistory();
	const { authLoading } = useSelector((state: IState) => state.loading);

	useEffect(() => {
		if (user) {
			history.push('/');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signIn = async (values: any) => {
		const { email, password } = values;

		try {
			await firebaseAuth().signInWithEmailAndPassword(email, password);
		} catch (error) {
			message.error('Email and password combination is incorrect!');
		}
	};

	return (
		<div className="w-full flex flex-wrap">
			<div className="w-full md:w-1/2 flex flex-col">
				<div className="bg-white container mx-auto px-4 h-full">
					<div className="flex content-center items-center justify-center h-full">
						<div className="bg-gray-900w-full w-full lg:w-8/12 px-4 sm:mt-20">
							<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
								<div className="rounded-t mb-0 px-6 py-6">
									<div className="btn-wrapper text-center">
										<GoogleLogin />
									</div>
									<hr className="mt-6 border-b-1 border-gray-400" />
								</div>
								<div className="flex-auto px-4 lg:px-10 py-10 pt-0">
									<div className="text-gray-500 text-center mb-3 font-bold">
										<small>Or sign in with credentials</small>
									</div>
									<Form layout="vertical" onFinish={signIn}>
										<div className="relative w-full mb-3">
											<Form.Item
												name="email"
												label="Email"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														type: 'email',
														message: 'The input is not valid E-mail!',
													},
												]}
											>
												<Input
													type="email"
													placeholder="Email"
													allowClear
													autoComplete="username"
												/>
											</Form.Item>
										</div>

										<div className="relative w-full mb-3">
											<Form.Item
												name="password"
												label="Passwrod"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														message: 'Password cannot be empty!',
													},
												]}
											>
												<Input.Password
													placeholder="Password"
													iconRender={(visible) =>
														visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
													}
													autoComplete="current-password"
												/>
											</Form.Item>
										</div>
										<div className="text-center mt-6">
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													loading={authLoading}
												>
													Sign In
												</Button>
											</Form.Item>
										</div>
									</Form>
								</div>
							</div>
							<div className="flex flex-wrap mt-6">
								<div className="w-1/2">
									<a href="/resetpassword" className="text-gray-300">
										<small>Forgot password?</small>
									</a>
								</div>
								<div className="w-1/2 text-right">
									<a href="/register" className="text-gray-300">
										<small>Create new account</small>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-1/2 shadow-2xl">
				<img
					className="object-cover w-full hidden md:block"
					src={require('../../assets/images/login.svg').default}
					alt="login-tshirt"
				/>
			</div>
		</div>
	);
};
