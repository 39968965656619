import {
	CreatedActions,
	DefaultActionTypes,
	createActions,
	createReducer,
} from 'reduxsauce';
import { ICanvasState, TShirtSide } from '../interface';

import { generateId } from '../utils/stringHelper';
import { tShirtColors } from '../data';

const { Types, Creators }: CreatedActions = createActions({
	saveCanvasState: ['data'],
	adminSaveCanvasState: ['data'],
	addToFeatured: ['data'],
	uploadDesignToStorage: ['data'],
	startTour: null,
	finishTour: null,
});

export const CanvasTypes: DefaultActionTypes = Types;

export const TSHIRT_FRONT_IMAGE = require('../assets/images/tshirt_front.webp')
	.default;
export const TSHIRT_BACK_IMAGE = require('../assets/images/tshirt_back.webp')
	.default;

export const INITIAL_STATE: ICanvasState = {
	id: generateId(),
	frontTShirtImage: TSHIRT_FRONT_IMAGE,
	backTShirtImage: TSHIRT_BACK_IMAGE,
	tShirtSide: TShirtSide.FRONT,
	printBackSide: false,
	tShirtColor: tShirtColors[Math.floor(Math.random() * tShirtColors.length)],
	tShirtSize: ['Adult', ['M', '11']],
	showTextControls: false,
	showImageControls: false,
	showGroupControls: false,
	isTourOpen: false,
	frontCanvasData: '',
	backCanvasData: '',
};

export const CanvasSelectors = {
	id: (state: { canvas: ICanvasState }) => state.canvas.id,
	printBackSide: (state: { canvas: ICanvasState }) =>
		state.canvas.printBackSide,
	tShirtColor: (state: { canvas: ICanvasState }) => state.canvas.tShirtColor,
	canvasState: (state: { canvas: ICanvasState }) => state.canvas,
};

export const saveCanvas = (
	state = INITIAL_STATE,
	action: { data: Partial<ICanvasState> }
): ICanvasState => {
	const { data } = action;
	return { ...state, ...data };
};

export const startTour = (state = INITIAL_STATE): ICanvasState => {
	return { ...state, isTourOpen: true };
};

export const finishTour = (state = INITIAL_STATE): ICanvasState => {
	return { ...state, isTourOpen: false };
};

export const HANDLERS = {
	[Types.SAVE_CANVAS_STATE]: saveCanvas,
	[Types.ADMIN_SAVE_CANVAS_STATE]: saveCanvas,
	[Types.START_TOUR]: startTour,
	[Types.FINISH_TOUR]: finishTour,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default Creators;
