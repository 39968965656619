import { ICartItem } from '../interface';

const BASE_TSHIRT_PRICE = 18;
const PRINT_BACK_ADDITIONAL_PRICE = 7;
const TAX_RATE = 0.0825;

export const getPrice = (printBackSide: boolean): number => {
	if (printBackSide) {
		return BASE_TSHIRT_PRICE + PRINT_BACK_ADDITIONAL_PRICE;
	}

	return BASE_TSHIRT_PRICE;
};

export const getTax = (price: number): number => {
	return price * TAX_RATE;
};

export const getCartTotal = (cartItems: ICartItem[]) => {
	return cartItems.reduce((acc, curr) => {
		const price = getPrice(curr.printBackSide);
		const tax = getTax(price);
		const priceIncludingTax = price + tax;
		return acc + curr.quantity * priceIncludingTax;
	}, 0);
};
