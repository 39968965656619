import { firebaseAuth, storage } from '../config/firebase';
import { reportError } from './Reporting';

export const uploadScreenshot = async (
	base64EncodedString: string,
	canvasId: string
): Promise<string> => {
	try {
		const user = firebaseAuth().currentUser;

		const designData = await fetch(base64EncodedString);
		const designBlob = await designData.blob();

		const storageRef = storage().ref();
		const fileRef = storageRef.child(`images/${user?.uid}/${canvasId}`);

		const uploadTask = await fileRef.put(designBlob);

		const downloadUrl = await uploadTask.ref.getDownloadURL();

		console.log('Uploaded a file', downloadUrl);

		return downloadUrl;
	} catch (error) {
		reportError(error);
		return '';
	}
};
