import { BgColorsOutlined, CloseOutlined } from '@ant-design/icons';
import { ColorResult, SketchPicker } from 'react-color';
import React, { useState } from 'react';

import { Button } from 'antd';

interface ITextColorPicker {
	textColor: string;
	showTextColorPicker: boolean;
	updateTextColorHandler: (
		color: ColorResult,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
}

const TextColorPicker = (props: ITextColorPicker) => {
	const { textColor, showTextColorPicker, updateTextColorHandler } = props;

	const [displayColorPicker, setdisplayColorPicker] = useState<boolean>(false);

	const handleClick = () => {
		setdisplayColorPicker(!displayColorPicker);
	};

	return (
		<>
			<Button
				disabled={showTextColorPicker}
				onClick={handleClick}
				size={'large'}
				icon={
					!displayColorPicker ? (
						<BgColorsOutlined
							style={{
								fontSize: '26px',
							}}
						/>
					) : (
						<CloseOutlined
							style={{
								fontSize: '26px',
								color: 'red',
							}}
						/>
					)
				}
				style={{ width: '100%', height: '50px' }}
			></Button>
			{!showTextColorPicker && displayColorPicker ? (
				<div style={{ position: 'absolute', zIndex: 10 }}>
					<SketchPicker
						color={textColor}
						onChange={updateTextColorHandler}
						disableAlpha
					/>
				</div>
			) : null}
		</>
	);
};

export default TextColorPicker;
