import { Layout } from 'antd';

import { Helmet } from 'react-helmet';
import Onboarding from '../components/Onboarding/Onboarding';
import AdminTShirtDesigner from '../container/AdminDesigner/AdminDesigner';

const { Content } = Layout;

const Designer = () => {
	return (
		<Content style={{ padding: '0 25px', marginTop: 70 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Designer</title>
			</Helmet>
			<AdminTShirtDesigner />
			<Onboarding />
		</Content>
	);
};

export default Designer;
