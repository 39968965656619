import CartActions, { CartSelectors } from '../redux/CartRedux';
import { FirebaseDocRef, ICartItem } from '../interface';
import { call, put, select } from 'redux-saga/effects';
import { firebaseAuth, firestore } from '../config/firebase';

import { reportError } from '../services/Reporting';

export function* fetchCart() {
	try {
		const user = firebaseAuth().currentUser;

		const cartRef = firestore().doc(`cart/${user?.uid}`);
		const doc: FirebaseDocRef = yield call([cartRef, cartRef.get]);
		const data = doc.data();
		const { items } = data || { items: [] };

		yield put(CartActions.fetchCartSuccess(items));
	} catch (error) {
		reportError(error);
		yield put(CartActions.fetchCartFailure());
	}
}

export function* updateCart() {
	try {
		const user = firebaseAuth().currentUser;
		if (!user) {
			return;
		}

		const cartItems: ICartItem[] = yield select(CartSelectors.cartItems);
		const cartRef = firestore().doc(`cart/${user.uid}`);

		if (!cartItems.length) {
			yield call([cartRef, cartRef.delete]);
			return;
		}

		// @ts-ignore
		yield call([cartRef, cartRef.set], { items: cartItems }, { merge: true });
	} catch (error) {
		reportError(error);
	}
}

export function* resetCart() {
	try {
		const user = firebaseAuth().currentUser;
		if (!user) {
			return;
		}

		const cartRef = firestore().doc(`cart/${user.uid}`);

		yield call([cartRef, cartRef.delete]);
	} catch (error) {
		reportError(error);
	}
}
