import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthActions from '../redux/AuthRedux';
import CartActions from '../redux/CartRedux';
import LoadingActions from '../redux/LoadingRedux';
import firebase from 'firebase/app';
import { firebaseAuth } from '../config/firebase';
import { message } from 'antd';
import { useDispatch } from 'react-redux';

export const useAuth = (onLogin?: any) => {
	const [user, setUser] = useState<firebase.User | null>(null);
	const history = useHistory();
	const location = useLocation();
	// @ts-ignore
	const { from } = location.state || { from: { pathname: '/' } };

	const dispatch = useDispatch();

	useEffect(() => {
		const unregisterAuthObserver = firebase
			.auth()
			.onAuthStateChanged((user) => {
				setUser(user);
				if (user) {
					dispatch(LoadingActions.setAuthLoading(true));
					const { email, emailVerified, uid, photoURL, displayName } = user;

					if (!emailVerified) {
						message.error('Please verify you email before login!');
						firebaseAuth().signOut();

						dispatch(LoadingActions.setAuthLoading(false));
						return;
					}

					dispatch(
						AuthActions.loginSuccess({
							user: {
								displayName,
								email,
								emailVerified,
								uid,
								photoURL,
							},
						})
					);

					dispatch(CartActions.fetchCartRequest());

					if (onLogin) {
						dispatch(LoadingActions.setAuthLoading(false));
						onLogin();

						return;
					}

					// TODO enable history replace instead of going back
					// temporarily navigating user back (design screen)
					// in order to take screenshot of the design
					// history.replace(from);
					dispatch(LoadingActions.setAuthLoading(false));
					history.replace(from);
				} else {
					dispatch(CartActions.resetCart());
					dispatch(AuthActions.logout());
				}
			});

		return () => unregisterAuthObserver();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return user;
};
