import {
	FileImageOutlined,
	FileTextOutlined,
	SearchOutlined,
	ShoppingCartOutlined,
} from '@ant-design/icons';

import FeatureList from './FeaturesList';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const featureList = [
	'Add text and customize according to your liking',
	'Upload images or search for one',
	'Choose from a range of t-shirt color and sizes',
	'Undo redo functionality',
];

export const Features = () => {
	return (
		<section>
			<div className="py-20 bg-gray-50 radius-for-skewed">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap items-center">
						<div className="w-full lg:w-1/2 mb-12 lg:mb-0">
							<div className="max-w-md lg:mx-auto">
								<h2 className="my-2 text-4xl lg:text-5xl font-bold font-heading">
									Design your own
								</h2>
								<p className="mb-6 text-purple-600 leading-loose font-bold">
									Easily create your design using our custom designer.
								</p>
								<FeatureList items={featureList} />
								<Link
									className="inline-block w-full lg:w-auto py-2 px-6 leading-loose text-white font-semibold bg-purple-600 border-2 border-purple-400 hover:border-purple-500 hover:text-white rounded-l-xl rounded-t-xl transition duration-200"
									to="/design"
								>
									Get started
								</Link>
							</div>
						</div>
						<div className="w-full lg:w-1/2 flex flex-wrap -mx-4">
							<div className="mb-8 lg:mb-0 w-full md:w-1/2 px-4">
								<ScrollAnimation animateOnce={true} animateIn="slideInDown">
									<div className="mb-8 py-6 pl-6 pr-4 shadow rounded bg-white">
										<span className="mb-4 inline-block p-3 rounded-lg bg-yellow-100">
											<FileImageOutlined
												style={{ fontSize: 24, color: '#d9770b' }}
											/>
										</span>
										<h3 className="mb-2 text-2xl font-bold font-heading">
											Image
										</h3>
										<p className="text-gray-500 leading-loose">
											Easily add your image to the designer.
										</p>
									</div>
								</ScrollAnimation>
								<ScrollAnimation animateOnce={true} animateIn="slideInLeft">
									<div className="py-6 pl-6 pr-4 shadow rounded bg-white">
										<span className="mb-4 inline-block p-3 rounded-lg bg-pink-100">
											<SearchOutlined
												style={{ fontSize: 24, color: '#db2777' }}
											/>
										</span>
										<h3 className="mb-2 text-2xl font-bold font-heading">
											Search
										</h3>
										<p className="text-gray-500 leading-loose">
											Don't have an image you are looking for? Use the search
											functionality to find one.
										</p>
									</div>
								</ScrollAnimation>
							</div>
							<div className="w-full md:w-1/2 lg:mt-20 px-4">
								<ScrollAnimation animateOnce={true} animateIn="slideInRight">
									<div className="mb-8 py-6 pl-6 pr-4 shadow rounded-lg bg-white">
										<span className="mb-4 inline-block p-3 rounded bg-blue-100">
											<FileTextOutlined
												style={{ fontSize: 24, color: '#2464eb' }}
											/>
										</span>
										<h3 className="mb-2 text-2xl font-bold font-heading">
											Text
										</h3>
										<p className="text-gray-500 leading-loose">
											Customize your text. Change font, text color and
											alignment.
										</p>
									</div>
								</ScrollAnimation>
								<ScrollAnimation animateOnce={true} animateIn="slideInUp">
									<div className="py-6 pl-6 pr-4 shadow rounded-lg bg-white">
										<span className="mb-4 inline-block p-3 rounded bg-purple-100">
											<ShoppingCartOutlined
												style={{ fontSize: 24, color: '#7d3aec' }}
											/>
										</span>
										<h3 className="mb-2 text-2xl font-bold font-heading">
											Checkout
										</h3>
										<p className="text-gray-500 leading-loose">
											Using integrated Stripe payment for checkout
										</p>
									</div>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Features;
