import {
	CreatedActions,
	DefaultActionTypes,
	createActions,
	createReducer,
} from 'reduxsauce';
import { IAuthState } from '../interface';
import firebase from 'firebase/app';

const { Types, Creators }: CreatedActions = createActions({
	loginSuccess: ['data'],
	logout: null,
});

export const AuthTypes: DefaultActionTypes = Types;

export const AuthSelectors = {
	user: (state: { auth: IAuthState }) => state.auth.user,
};

export const INITIAL_STATE: IAuthState = {
	user: undefined,
};

const login = (
	state = INITIAL_STATE,
	action: { data: firebase.User | null }
): IAuthState => {
	const { data } = action;

	return { ...state, ...data };
};

const logout = (state = INITIAL_STATE): IAuthState => {
	return { ...state, user: undefined };
};

const HANDLERS = {
	[Types.LOGIN_SUCCESS]: login,
	[Types.LOGOUT]: logout,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default Creators;
