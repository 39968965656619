import 'antd/dist/antd.css';
import 'tailwindcss/dist/tailwind.css';
import './App.css';

import { Route, Switch } from 'react-router-dom';
import configStore, { history } from './configStore';

import AdminDesigner from './pages/AdminDesigner';
import AllDesigns from './pages/AllDesigns';
import AppFooter from './components/Footer/Footer';
import AppHeader from './components/Header/AppHeader';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Checkout from './pages/Checkout';
import CheckoutCanceled from './pages/CheckoutCanceled';
import CheckoutSuccess from './pages/CheckoutSuccess';
import { ConnectedRouter } from 'connected-react-router';
import Designer from './pages/Designer';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import { Layout } from 'antd';
import Login from './pages/Login';
import Orders from './pages/Orders';
import { PersistGate } from 'redux-persist/integration/react';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { Provider } from 'react-redux';
import Register from './pages/Register';
import TermsOfUse from './pages/TermsOfUse';

const { store, persistor } = configStore();

const App = () => {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<PersistGate persistor={persistor}>
					<Layout>
						<AppHeader />
						<Switch>
							<Route path="/design/:id" component={Designer} />
							<Route path="/design" component={Designer} />
							<Route path="/admindesigner" component={AdminDesigner} />
							<Route path="/designs" component={AllDesigns} />
							<Route path="/login" component={Login} />
							<Route path="/register" component={Register} />
							<Route path="/resetpassword" component={ForgotPassword} />
							<Route path="/privacypolicy" component={PrivacyPolicy} />
							<Route path="/termsofuse" component={TermsOfUse} />
							<AuthenticatedRoute path="/checkout" component={Checkout} />
							<AuthenticatedRoute
								path="/checkoutsuccess"
								component={CheckoutSuccess}
							/>
							<AuthenticatedRoute
								path="/checkoutcanceled"
								component={CheckoutCanceled}
							/>
							<AuthenticatedRoute path="/orders" component={Orders} />
							<Route path="/" component={Home} />
						</Switch>
						<AppFooter />
					</Layout>
				</PersistGate>
			</ConnectedRouter>
		</Provider>
	);
};

export default App;
