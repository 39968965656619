import { Checkbox, Radio, Space } from 'antd';
import { IState, TShirtSide } from '../../interface';
import { useDispatch, useSelector } from 'react-redux';

import CanvasActions from '../../redux/CanvasRedux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';

const options = [
	{ label: 'Front', value: 'FRONT' },
	{ label: 'Back', value: 'BACK' },
];

interface ITShirtSideProps {
	canvas: fabric.Canvas;
}

export const TShirtSideSelector = (props: ITShirtSideProps) => {
	const canvasState = useSelector((state: IState) => state.canvas);
	const dispatch = useDispatch();

	const {
		frontCanvasData,
		backCanvasData,
		tShirtSide,
		printBackSide,
	} = canvasState;

	const { canvas } = props;

	const showFrontView = () => {
		if (tShirtSide === TShirtSide.BACK) {
			dispatch(
				CanvasActions.saveCanvasState({
					backCanvasData: JSON.stringify(canvas),
					tShirtSide: TShirtSide.FRONT,
				})
			);

			canvas.clear();

			if (frontCanvasData) {
				canvas.loadFromJSON(frontCanvasData, () => canvas.renderAll());
			}
		} else {
			dispatch(
				CanvasActions.saveCanvasState({
					frontCanvasData: JSON.stringify(canvas),
				})
			);
		}
	};

	const showBackView = () => {
		if (tShirtSide === TShirtSide.FRONT) {
			dispatch(
				CanvasActions.saveCanvasState({
					frontCanvasData: JSON.stringify(canvas),
					tShirtSide: TShirtSide.BACK,
				})
			);

			canvas.clear();
			if (backCanvasData) {
				canvas.loadFromJSON(backCanvasData, () => canvas.renderAll());
			}
		}
	};

	const printBackSideHandler = (event: CheckboxChangeEvent) => {
		const printBackSide = event.target.checked;

		dispatch(
			CanvasActions.saveCanvasState({
				printBackSide,
			})
		);

		if (printBackSide) {
			showBackView();
		} else {
			showFrontView();
		}
	};

	const onTShirtSideChange = (e: RadioChangeEvent) => {
		switch (e.target.value) {
			case TShirtSide.FRONT:
				showFrontView();
				break;
			case TShirtSide.BACK:
				showBackView();
				break;
			default:
				return;
		}
	};

	return (
		<>
			<Space direction={'vertical'}>
				<Checkbox onChange={printBackSideHandler} checked={printBackSide}>
					Print back side?
				</Checkbox>
				{printBackSide && (
					<Radio.Group
						options={options}
						onChange={onTShirtSideChange}
						defaultValue="FRONT"
						size="large"
						optionType="button"
						buttonStyle="solid"
						value={tShirtSide === TShirtSide.FRONT ? 'FRONT' : 'BACK'}
					/>
				)}
			</Space>
		</>
	);
};

export default TShirtSideSelector;
