import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import appSagas from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import localforge from 'localforage';
import { reducers } from './redux';
import { routerMiddleware } from 'connected-react-router';

const persistConfig = {
	key: 'root',
	storage: localforge,
	blacklist: ['loading'],
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, reducers(history));

export const configureStore = () => {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		persistedReducer,
		composeWithDevTools(
			applyMiddleware(routerMiddleware(history), sagaMiddleware)
		)
	);
	const persistor = persistStore(store);

	sagaMiddleware.run(appSagas);

	return { store, persistor };
};

export default configureStore;
