import { Button, Modal } from 'antd';

import { IState } from '../../interface';
import { ShoppingOutlined } from '@ant-design/icons';
import { firebaseAuth } from '../../config/firebase';
import { generateId } from '../../utils/stringHelper';
import { loadStripe } from '@stripe/stripe-js';
import { reportError } from '../../services/Reporting';
import { useSelector } from 'react-redux';
import { useState } from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

export const StripeCheckout = () => {
	const [loading, setLoading] = useState(false);
	const cart = useSelector((state: IState) => state.cart);
	const canvasId = useSelector((state: IState) => state.canvas.id);
	const user = firebaseAuth().currentUser;
	const userId = user?.uid || '';
	const customerEmail = user?.email;
	const checkoutId = `CP-${generateId()}`;

	const handleCheckout = async () => {
		try {
			setLoading(true);

			const checkoutData = {
				cart,
				userId,
				customerEmail,
				checkoutId,
				canvasId,
			};

			const stripe = await stripePromise;
			const response = await fetch('/api/create-checkout-session', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(checkoutData),
			});

			const session = await response.json();
			const result = await stripe?.redirectToCheckout({
				sessionId: session.id,
			});

			if (result?.error) {
				reportError(result.error.message);
				showErroModal();
			} else {
				console.log(result);
				showErroModal();
			}
		} catch (error) {
			reportError(error);
			showErroModal();
		} finally {
			setLoading(false);
		}
	};

	const showErroModal = () => {
		Modal.error({
			title: 'Oops! something went wrong',
			content:
				'An error occurred when trying to initiate checkout. Please try again!',
			onOk: () => {},
		});
	};

	return (
		<Button
			shape="round"
			size={'large'}
			icon={<ShoppingOutlined style={{ fontSize: '20px', color: '#08c' }} />}
			onClick={handleCheckout}
			loading={loading}
		>
			CHECKOUT
		</Button>
	);
};

export default StripeCheckout;
