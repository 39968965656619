import {
	CreatedActions,
	DefaultActionTypes,
	createActions,
	createReducer,
} from 'reduxsauce';
import { ICartItem, ICartState } from '../interface';

const { Types, Creators }: CreatedActions = createActions({
	fetchCartRequest: null,
	fetchCartSuccess: ['data'],
	fetchCartFailure: null,
	addCartItem: ['data'],
	removeCartItem: ['data'],
	updateCartItem: ['data'],
	resetCart: null,
	setCartLoading: ['data'],
});

export const CartTypes: DefaultActionTypes = Types;

export const CartSelectors = {
	cartItems: (state: { cart: ICartState }) => state.cart.items,
	cartError: (state: { cart: ICartState }) => state.cart.error,
};

export const INITIAL_STATE: ICartState = {
	items: [],
	error: false,
	loading: false,
};

export const fetchCartSuccess = (
	state = INITIAL_STATE,
	action: { data: ICartItem[] }
): ICartState => {
	const { data } = action;

	return { ...state, items: data, error: false };
};

export const fetchCartFailure = (state = INITIAL_STATE): ICartState => {
	return { ...state, error: true };
};

const addCartItem = (
	state = INITIAL_STATE,
	action: { data: ICartItem }
): ICartState => {
	const { data } = action;
	const { items } = state;

	return { ...state, items: items.concat(data) };
};

const removeCartItem = (
	state = INITIAL_STATE,
	action: { data: ICartItem }
): ICartState => {
	const { data } = action;
	const { items } = state;

	return { ...state, items: items.filter((item) => item.id !== data.id) };
};

const updateCartItem = (
	state = INITIAL_STATE,
	action: { data: ICartItem }
): ICartState => {
	const { data } = action;
	const { items } = state;

	return {
		...state,
		items: items
			.map((item) => {
				if (item.id === data.id) {
					return {
						...item,
						...data,
					};
				}

				return item;
			})
			.filter((item) => item.quantity !== 0),
	};
};

const resetCart = (state = INITIAL_STATE): ICartState => {
	return { ...state };
};

const setCartLoading = (
	state = INITIAL_STATE,
	action: { data: boolean }
): ICartState => {
	const { data } = action;
	return { ...state, loading: data };
};

const HANDLERS = {
	[Types.FETCH_CART_SUCCESS]: fetchCartSuccess,
	[Types.FETCH_CART_FAILURE]: fetchCartFailure,
	[Types.ADD_CART_ITEM]: addCartItem,
	[Types.REMOVE_CART_ITEM]: removeCartItem,
	[Types.UPDATE_CART_ITEM]: updateCartItem,
	[Types.RESET_CART]: resetCart,
	[Types.SET_CART_LOADING]: setCartLoading,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default Creators;
