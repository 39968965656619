import { Button, Result } from 'antd';

import CanceledCheckout from '../components/Icons/CanceledCheckout';
import { Content } from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const CheckoutCanceledIcon = () => <Icon component={CanceledCheckout} />;

export const CheckoutCanceled = () => {
	const history = useHistory();

	const navigateToPath = (pathname: string) => {
		history.push({ pathname });
	};

	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Checkout Canceled</title>
			</Helmet>
			<Result
				title="Order canceled"
				subTitle="Continue to shop around and checkout when you're ready."
				icon={<CheckoutCanceledIcon />}
				extra={[
					<Button onClick={() => navigateToPath('/')} key="home">
						Back Home
					</Button>,

					<Button
						type="primary"
						onClick={() => navigateToPath('checkout')}
						key="checkout"
					>
						Checkout
					</Button>,
				]}
			/>
		</Content>
	);
};

export default CheckoutCanceled;
