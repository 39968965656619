import { Button, Form, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { firebaseAuth } from '../../config/firebase';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export const RegisterForm = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const register = async (values: any) => {
		setLoading(true);
		const { email, password } = values;

		try {
			await firebaseAuth().createUserWithEmailAndPassword(email, password);
			const user = firebaseAuth().currentUser;

			if (user) {
				user.sendEmailVerification();
				message.info(
					'Verfication email sent to your email address. Please follow instructions to verify your email.'
				);
			}
			history.push({ pathname: '/login' });
		} catch (error) {
			message.error('Unable to register, please try again!');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="w-full flex flex-wrap">
			<div className="w-full md:w-1/2 flex flex-col">
				<div className="bg-white container mx-auto px-4 h-full">
					<div className="flex content-center items-center justify-center h-full">
						<div className="bg-gray-900w-full w-full lg:w-8/12 px-4 sm:mt-20">
							<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
								<div className="flex-auto px-10 py-10">
									<Form layout="vertical" onFinish={register}>
										<div className="relative w-full mb-3">
											<Form.Item
												name="email"
												label="Email"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														type: 'email',
														message: 'The input is not valid E-mail!',
													},
												]}
											>
												<Input type="email" placeholder="Email" allowClear />
											</Form.Item>
										</div>

										<div className="relative w-full mb-3">
											<Form.Item
												name="password"
												label="Passwrod"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														message: 'Password format is invalid',
														// type: 'regexp',
														// pattern: new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$")
													},
												]}
											>
												<Input.Password
													placeholder="Password"
													iconRender={(visible) =>
														visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
													}
												/>
											</Form.Item>
										</div>
										<div className="relative w-full mb-3">
											<Form.Item
												name="confirm-password"
												label="Confirm Passwrod"
												className="block uppercase text-gray-700 text-xs font-bold mb-2"
												rules={[
													{
														required: true,
														message: 'Confirm password cannot be empty!',
													},
												]}
											>
												<Input.Password
													placeholder="Confirm Password"
													iconRender={(visible) =>
														visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
													}
												/>
											</Form.Item>
										</div>
										<div className="text-center mt-6">
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													loading={loading}
												>
													Register
												</Button>
											</Form.Item>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-1/2 shadow-2xl">
				<img
					className="object-cover w-full hidden md:block"
					src={require('../../assets/images/register.svg').default}
					alt="register-tshirt"
				/>
			</div>
		</div>
	);
};

export default RegisterForm;
