import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import ShoppingCart from '../components/Cart/ShoppingCart';

const { Content } = Layout;

const Checkout = () => {
	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Checkout</title>
			</Helmet>
			<ShoppingCart />
		</Content>
	);
};

export default Checkout;
