import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import { PrivacyPolicy as PrivacyPolicyContent } from '../components/PrivacyPolicy';

const { Content } = Layout;

export const PrivacyPolicy = () => {
	return (
		<Content style={{ marginTop: 64 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Privacy Policy</title>
			</Helmet>
			<PrivacyPolicyContent />
		</Content>
	);
};

export default PrivacyPolicy;
