import { Content } from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';
import TShirtDesignList from '../components/TShirtDesignList';

export const AllDesigns = () => {
	return (
		<>
			<Content style={{ padding: '0 50px', marginTop: 75 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>All Designs</title>
			</Helmet>
				<TShirtDesignList limit={20} />
			</Content>
		</>
	);
};

export default AllDesigns;
