import '../Designer/TShirtDesigner';

import CanvasActions from '../../redux/CanvasRedux';
import {
	Col,
	Divider,
	Layout,
	message,
	Modal,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import {
	ElementControls,
	HistoryControls,
	SaveControls,
	TextControls,
} from '../../components/CanvasControls';
import { ICanvasState, IState, TShirtSide } from '../../interface';
import { createRef, useEffect, useRef, useState } from 'react';
import {
	hideDimensionText,
	initAligningGuidelines,
	keyDownHandler,
	setupCanvasBoundary,
	showDimensionText,
} from '../../utils/fabricCanvasHelper';
import { useDispatch, useSelector } from 'react-redux';

import FontFaceObserver from 'fontfaceobserver';
import { IEvent } from 'fabric/fabric-impl';
import ImageControls from '../../components/CanvasControls/ImageControls';
import { ImageUpload } from '../../components/ImageUpload';
import { LoadingOutlined } from '@ant-design/icons';
import { TShirtCanvas } from '../../components/Canvas';
import TShirtColorPicker from '../../components/TShirtColorPicker/TShirtColorPicker';
import TShirtSideSelector from '../../components/TShirtSideSelector';
import { fabric } from 'fabric';
import 'fabric-history';
import { fonts } from '../../data';
import { reportError } from '../../services/Reporting';
import DataSource from './DataSource';

const { Content } = Layout;
const { Title } = Typography;

const AdminTShirtDesigner = () => {
	const canvasRef = createRef<HTMLDivElement>();
	const canvasState = useSelector((state: IState) => state.canvas);
	const dispatch = useDispatch();
	const canvasStateRef = useRef(canvasState);

	useEffect(() => {
		canvasStateRef.current = canvasState;
	}, [canvasState]);

	const [canvasInitializing, setCanvasInitializing] = useState(true);

	const canvas = useRef<any>();
	const { frontCanvasData, tShirtColor, tShirtSize } = canvasStateRef.current;

	const dimensionText = new fabric.Text('', {
		fontSize: 15,
		visible: false,
		hasControls: false,
		excludeFromExport: true,
		textBackgroundColor: '#fdc7d7',
	});

	const saveCanvasState = (updatedValue: Partial<ICanvasState>) => {
		dispatch(CanvasActions.adminSaveCanvasState({ ...updatedValue }));
	};

	useEffect(() => {
		const loadFontsInitializeCanvasData = async () => {
			try {
				const fontObservers = fonts.map((font) => {
					const fontObserver = new FontFaceObserver(font);
					return fontObserver.load(undefined, 10000);
				});

				await Promise.all(fontObservers);
			} catch (error) {
				setCanvasInitializing(false);
				reportError(error);

				Modal.error({
					title: 'Oops! something went wrong',
					content: 'Please refresh to try again!',
					onOk: () => {},
				});
			}

			setCanvasInitializing(false);

			await initCanvas();
		};

		loadFontsInitializeCanvasData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initCanvas = async () => {
		canvas.current = new fabric.Canvas('canvas', {
			preserveObjectStacking: true,
			backgroundColor: undefined,
			width: 500,
			height: 700,
		});

		saveCanvasState({
			tShirtSide: TShirtSide.FRONT,
		});

		if (frontCanvasData) {
			canvas.current.loadFromJSON(frontCanvasData, () =>
				canvas.current.renderAll()
			);
		}

		saveCanvasState({
			tShirtColor,
		});

		canvas.current.add(dimensionText);

		// setup guidelines, triggered when object moving
		initAligningGuidelines(canvas.current);
		setupEventListenersOnCanvas();
		setupCanvasBoundary(canvas.current, dimensionText);
	};

	const setupEventListenersOnCanvas = () => {
		canvas.current.on({
			changed: saveCanvasDelay,
			'object:added': saveCanvasDelay,
			'object:modified': saveCanvasDelay,
			'object:removed': saveCanvasDelay,
			'selection:cleared': onObjectSelectionCleared,
			'selection:created': onObjectSelected,
			'selection:updated': onObjectSelected,
		});

		canvas.current.on('object:moved', function (event: IEvent) {
			const object = event.target;

			if (object) {
				const { height = 0, scaleY = 1, top = 0, left = 0 } = object;

				dimensionText.set({
					left: left + 10,
					top: top + height * scaleY + 7,
					visible: true,
				});
				showDimensionText(dimensionText);
			}
		});

		canvas.current.on('object:scaling', (event: IEvent) => {
			const object = event.target;

			if (object) {
				const {
					width = 0,
					height = 0,
					scaleX = 1,
					scaleY = 1,
					top = 0,
					left = 0,
				} = object;

				const canvasWidth = canvas.current.getWidth();
				const canvasHeight = canvas.current.getHeight();

				const scaledWidth = (
					((width * scaleX) / canvasWidth) *
					// @ts-ignore
					parseFloat(tShirtSize[1][1] || 0)
				).toFixed(1);
				const scaledHeight = (((height * scaleY) / canvasHeight) * 16).toFixed(
					0
				);

				dimensionText.set({
					left: left + 10,
					top: top + height * scaleY + 7,
					text: `${scaledWidth} X ${scaledHeight} inch`,
					visible: true,
				});
			}
		});
	};

	const onObjectSelected = (event: IEvent) => {
		// @ts-ignore
		const { target, selected } = event;
		const selectedObject = target;
		if (selectedObject) {
			const { type } = selectedObject;
			if (type === 'text' || type === 'i-text') {
				saveCanvasState({
					showTextControls: true,
					showImageControls: false,
				});
			} else if (type === 'image') {
				saveCanvasState({
					showTextControls: false,
					showImageControls: true,
				});
			} else if (
				type === 'group' ||
				(type === 'activeSelection' && selected.length > 1)
			) {
				saveCanvasState({
					showGroupControls: true,
					showTextControls: false,
					showImageControls: true,
				});
			}

			showDimensionText(dimensionText);

			window.addEventListener(
				'keydown',
				(event: KeyboardEvent) => keyDownHandler(event, canvas.current),
				{ passive: true }
			);
		}
	};

	const onObjectSelectionCleared = () => {
		saveCanvasState({
			showGroupControls: false,
			showTextControls: false,
			showImageControls: false,
		});

		hideDimensionText(dimensionText);

		// Remove event listeners on cleanup
		window.removeEventListener('keydown', (event: KeyboardEvent) =>
			keyDownHandler(event, canvas.current)
		);
	};

	const saveCanvasDelay = () => {
		setTimeout(saveCanvas, 500);
	};

	const saveCanvas = () => {
		try {
			hideDimensionText(dimensionText);
			const currentCanvasData = JSON.stringify(canvas.current);

			if (canvasStateRef.current.tShirtSide === TShirtSide.FRONT) {
				saveCanvasState({
					frontCanvasData: currentCanvasData,
				});
			} else {
				saveCanvasState({
					backCanvasData: currentCanvasData,
				});
			}
		} catch (error) {
			reportError(error);
			message.warn('Failed to save');
		}
	};

	return (
		<Content>
			<Row gutter={[10, 10]} id="tour-step1">
				<Col
					sm={{ span: 24, order: 1 }}
					md={{ span: 24, order: 1 }}
					lg={{ span: 12, order: 2 }}
				>
					{canvasInitializing ? (
						<Spin
							size="large"
							indicator={<LoadingOutlined spin />}
							style={{
								display: 'flex',
								justifyContent: 'center',
								margin: '5% auto',
							}}
						/>
					) : (
						<>
							<div ref={canvasRef}>
								<TShirtCanvas canvas={canvas.current} />
							</div>
						</>
					)}
				</Col>
				<Col
					sm={{ span: 24, order: 2 }}
					md={{ span: 24, order: 2 }}
					lg={{ span: 6, order: 1 }}
					xs={24}
				>
					<div id="tour-step5">
						<Row gutter={[32, 32]}>
							<Col flex={1}>
								<Title level={4}>Data source</Title>
								<DataSource />
							</Col>
						</Row>
						<Divider />
						<Row gutter={[32, 32]}>
							<Col flex={1} style={{ margin: '5px 0px' }}>
								<Title level={4}>T-Shirt Side</Title>
								<TShirtSideSelector canvas={canvas.current} />
							</Col>
						</Row>
					</div>
					<Divider />
					<Row gutter={[32, 32]}>
						<Col flex={1} id="tour-step6">
							<TShirtColorPicker />
						</Col>
					</Row>
					<Divider />
					<Row gutter={[32, 32]}>
						<Col flex={1}>
							<Title level={4}>Image </Title>
							<div id="tour-step4">
								<Space direction="horizontal">
									<ImageUpload canvas={canvas.current} />
									<ImageControls canvas={canvas.current} />
								</Space>
							</div>
						</Col>
					</Row>
					<Divider />
				</Col>
				<Col
					sm={{ span: 24, order: 2 }}
					md={{ span: 24, order: 2 }}
					lg={{ span: 6, order: 3 }}
					xs={24}
				>
					<Row gutter={[10, 10]}>
						<Col lg={24} md={24} sm={24} xs={24} id="tour-step3">
							<TextControls canvas={canvas.current} />
						</Col>
					</Row>
					<Divider />
					<Row gutter={[10, 10]}>
						<Col lg={24} md={24} sm={24} xs={24}>
							<Space direction={'vertical'} style={{ width: '100%' }}>
								<ElementControls canvas={canvas.current} />
								<HistoryControls canvas={canvas.current} />
							</Space>
						</Col>
					</Row>
					<Divider />
					<Row gutter={[32, 32]}>
						<Col xs={24} sm={24}>
							<SaveControls canvas={canvas.current} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Content>
	);
};

export default AdminTShirtDesigner;
